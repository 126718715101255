import React from "react";
import { useHistory } from "react-router-dom";
import { TopMenu, BottomMenu, LoginTimeout } from "../../components";
import { ROOT_PATH } from '../../constants';
import { syncTexts } from "../../util"

export default function (props) {
   let history = useHistory();
   let lang    = JSON.parse(localStorage.getItem("lang")) || "en";
   let [textObj, setTextObj] = React.useState("")

   React.useEffect(async () => {
    if (!textObj) {
      setTextObj(JSON.parse(localStorage.getItem("texts")))
      let t = await syncTexts(); 
      setTextObj(t);
    }
  })

   return (
    <>
      <div style={{ minHeight: "1100px" }}>
      <br></br><br></br>
      <TopMenu navLink   = "/"
               pageTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["Settings"]["name"]}
               title     = {textObj && textObj[lang]["Components"]["TopMenu"]["Settings"]["title"]}
               icon      = "X">
      </TopMenu>
        <table align="center" width="80%">
            <tr>
               <td colspan="3"><br></br><br></br><br></br></td>
            </tr>
            <tr>
            <td align="center"
                class="box1003"
                width="100%"
                style={{cursor:"pointer"}}
                onClick={() => { 
                	history.push("/")
                }}
                title={textObj && textObj[lang]["Components"]["Buttons"]["ChangeLang"]["title"]}
            >
               <a title={textObj && textObj[lang]["Components"]["Buttons"]["ChangeLang"]["title"]}>
                <font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["ChangeLang"]["name"]}</font> 
               </a>
            </td>
            <td width="20%"></td>
          </tr>
          <tr>
            <td colspan="3"><br></br><br></br></td>
          </tr>
          <tr>
            <td align="center"
                class="box1003"
                width="100%"
                style={{cursor:"pointer"}}
                onClick={() => { history.push("/password"); }}
                title={textObj && textObj[lang]["Components"]["Buttons"]["ChangePassword"]["title"]}
            >
               <a title={textObj && textObj[lang]["Components"]["Buttons"]["ChangePassword"]["title"]}>
                <font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["ChangePassword"]["name"]}</font> </a>
            </td>
            <td width="20%"></td>
          </tr>
          <tr>
            <td colspan="3"><br></br><br></br></td>
          </tr>
          <tr>
            <td align="center"
                class="box1003"
                width="100%"
                onClick={() => { history.push("/currency/main"); window.location.reload(); }}
                title={textObj && textObj[lang]["Components"]["Buttons"]["ChangeCurrency"]["title"]}
                style={{cursor:"pointer"}}
            >
              <a title={textObj && textObj[lang]["Components"]["Buttons"]["ChangeCurrency"]["title"]}>
                <font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["ChangeCurrency"]["name"]}</font> </a>
            </td>
            <td width="20%"></td>
          </tr>
          <br></br><br></br>
          <tr>
            <td align="center"
                class="box1003"
                width="100%"
                onClick={() => { history.push("/"); }}
                title={textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["title"]}
                style={{cursor:"pointer"}}
            >
              <a title={textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["title"]}>
                <font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["name"]}</font> </a>
            </td>
            <td width="20%"></td>
          </tr>
          <br></br><br></br>
          <tr>
          <td align="center"
                class="box1003"
                width="100%"
                onClick={() => { window.location.replace("https://www.youtube.com/results?search_query=QUADRATE+Ecosystem"); }}
                title="YouTube"
                style={{cursor:"pointer"}}
            >
              <a title="YouTube">
                <font size="7" color="#003e4d">YouTube</font>
              </a>
          </td>
          <td width="20%"></td>
          </tr>
       </table>
    <br></br><br></br><br></br><br></br><br></br>
    </div>
   <BottomMenu lang={lang} textObj={textObj}/>
   <LoginTimeout/>
  </>
 )
}
