import React from "react";
import ReactDOM from 'react-dom';
import { useHistory } from "react-router-dom";
import { TopMenu, BottomMenu, LoginTimeout} from "../../components";
import { syncTexts } from "../../util"

export default function (props) {
    let history = useHistory();
    let lang    = JSON.parse(localStorage.getItem("lang")) || "en";

    let [textObj, setTextObj] = React.useState("")

    React.useEffect(async () => {
      if (!textObj) {
        setTextObj(JSON.parse(localStorage.getItem("texts")))
        let t = await syncTexts(); 
        setTextObj(t);
      }
    })

    return (
      <>
      <div style={{ minHeight: "1100px" }}>
      <br></br><br></br>
      <TopMenu
           navLink   = "/"
           pageTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["Form"]["name"]}
           menuTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["Form"]["title"]}
           icon      = "X">
      </TopMenu>
      <table align="center" width="100%" style={{marginLeft: "8%", width: "80%"}}>
      <tr align="left">
        <td width="100%">
          <br></br><br></br><br></br><br></br>
          <font size="6" color="#003e4d">
          {textObj && textObj[lang]["PageText"]["FormPage"][0]}
          </font>
          <br></br><br></br><br></br>
          <font size="6" color="#003e4d">
            {textObj && textObj[lang]["PageText"]["FormPage"][1]}         
          </font>
        </td>
      </tr>
      <br></br>
      <tr>
        <td align="left">
          <font size="6" color="#8B0000">quadrate.systems@gmail.com</font>
        </td>
      </tr>
    </table>
   </div>
   <table align="center" width="84%">
     <tbody>
      <tr>
       <td align="center">
         <button
           style={{cursor: "pointer", fontSize: "42px", paddingTop: "1.8%", paddingBottom: "1.8%",
             fontWeight: "500", background: "#fff", color: "#003e4d", width: "101%"}}
           align="center"
           className="box1003"
           onClick={(e) => { e.preventDefault(); history.push("/"); }}
           title={textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["title"]}><font size="7">
             {textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["name"]}</font>
         </button>
       </td>
     </tr><br></br><br></br><br></br>
   </tbody>
  </table>
  <BottomMenu lang={lang} textObj={textObj}/>
 <LoginTimeout/>
</>
)
}
