import React from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { TopMenu, BottomMenu, SnackBar } from "../../components";
import { ROOT_PATH, API_PATH, MESSAGES} from '../../constants';
import { syncTexts } from "../../util"

export default function (props) {
   let history = useHistory();
   let lang    = JSON.parse(localStorage.getItem("lang")) || "en";
   let token   = localStorage.getItem("token");

   let [snackbar, setSnackbar] = React.useState({ isVisible: false, text: "", color: "" });
   let [loginFieldsText, setLoginFieldsText] = React.useState("");
   let [passwordFieldsText, setPasswordFieldsText] = React.useState("");
   let [showPassword, setShowPassword] = React.useState(false);
   let [textObj, setTextObj] = React.useState("")

   React.useEffect(async () => {
      if (!textObj) {
        setTextObj(JSON.parse(localStorage.getItem("texts")))
        let t = await syncTexts(); 
        setTextObj(t);
      }
   })

   let login = () => {
     if (loginFieldsText.length < 10 ||
       loginFieldsText.length > 30) {
         setSnackbar({
            text: textObj[lang]["Components"]["Messages"]["loginRules"],
            isVisible: true,
            color: "#2b2b2b",
         });
         return;
       }

      if (passwordFieldsText.length < 10 ||
        passwordFieldsText.length > 30) {
          setSnackbar({
             text: textObj[lang]["Components"]["Messages"]["loginRules"],
             isVisible: true,
             color: "#2b2b2b",
          });
          return;
        }

      axios
         .post(`${API_PATH}/login`, { login: loginFieldsText, password: passwordFieldsText, token: token, lang: lang})
         .then(({ data }) => {
            if (data.status == "success") {
               localStorage.setItem("token", data.token);
               localStorage.setItem("user", JSON.stringify(data.user));
               let user = JSON.parse(localStorage.getItem("user"));
               localStorage.setItem("referrer", JSON.stringify(user.parent));  
               axios
                 .post(`${API_PATH}/contract/getAll`, { address: "0x0" })
                  .then(({ data }) => {
                      if (data.status == "success")
                         localStorage.setItem("contract", JSON.stringify(data.contract));                    
                  })
                  .catch((err, res) => {
                     setSnackbar({ isVisible: true, text: data.message, color: "#2b2b2b" });
                  });
                  history.push("/address/all")
            } else {
               setSnackbar({ isVisible: true, text: data.message, color: "#2b2b2b" });
            }
         })
         .catch((err, data) => {
           setSnackbar({ isVisible: true, text: textObj[lang]["Components"]["Messages"]["serverError"], color: "#2b2b2b" });
           console.log(err)
         });
   }

  return (
   <>
   {token && history.push("/address/all")}
    <br></br><br></br>
    <TopMenu
        navLink   = "/"
        pageTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["Login"]["name"]}
        menuTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["Login"]["title"]}
        icon      = "X">
   </TopMenu>

    <table align="center" width="80%">
      <tbody>
       <tr>
        <td align="center">
           <form>
             <br></br><br></br><br></br>
             <input
                style={{width: "101%", fontSize: "42px", paddingTop: "1.8%", paddingBottom: "1.8%", paddingLeft: "1%"}}
                className="box1003"
                type="text"
                placeholder={textObj && textObj[lang]["Components"]["Placeholders"]["Login"]["name"]}
                title={textObj && textObj[lang]["Components"]["Placeholders"]["Login"]["title"]}
                id="login"
                maxLength="30"
                value={loginFieldsText}
                onChange={(e) => setLoginFieldsText(e.target.value)}
             />
             <br></br><br></br><br></br>
             <input
                style={{width: "101%", fontSize: "42px", paddingTop: "1.8%", paddingBottom: "1.8%", paddingLeft: "1%"}}
                className="box1003"
                type={showPassword ? "text" : "password"}
                placeholder={textObj && textObj[lang]["Components"]["Placeholders"]["Password"]["name"]}
                title={textObj && textObj[lang]["Components"]["Placeholders"]["Password"]["title"]}
                maxLength="30"
                id="password"
                value={passwordFieldsText}
                onChange={(e) => setPasswordFieldsText(e.target.value)}
             />
             <br></br><br></br>
             <p style={{float: "right", position: "relative"}}>
              <font size="6" color="#003e4d">{textObj && textObj[lang]["Components"]["CheckBoxes"]["ShowPassword"]["name"]}&nbsp;&nbsp;&nbsp;&nbsp;</font>
              <input
                type="checkbox"
                placeholder={textObj && textObj[lang]["Components"]["CheckBoxes"]["ShowPassword"]["title"]}
                title={textObj && textObj[lang]["Components"]["CheckBoxes"]["ShowPassword"]["title"]}
                id="password"
                onChange={(e) => setShowPassword(!showPassword)}
                style={{border: "2px solid", transform: "scale(2.0, 2.0)", top: "37%",
                  right: "-2%", position: "absolute"}}
             />
             </p>
             <br></br><br></br><br></br><br></br>
             <button
                style={{cursor: "pointer", fontSize: "42px", paddingTop: "1.8%", paddingBottom: "1.8%",
                 fontWeight: "500", background: "#fff", color: "#003e4d", width: "101%"}}
                align="center"
                className="box1003"
                onClick={(e) => { e.preventDefault();  login(); }}
                title={textObj && textObj[lang]["Components"]["Buttons"]["Login"]["title"]}>
              {textObj && textObj[lang]["Components"]["Buttons"]["Login"]["name"]}
             </button>
             <br></br><br></br><br></br>
             <button
                style={{cursor: "pointer", fontSize: "42px", paddingTop: "1.8%", paddingBottom: "1.8%",
                 fontWeight: "500", background: "#fff", color: "#003e4d", width: "101%"}}
                align="center"
                className="box1003"
                onClick={(e) => { e.preventDefault();  history.push("/register"); }}
                title={textObj && textObj[lang]["Components"]["Buttons"]["Register"]["title"]}>
              {textObj && textObj[lang]["Components"]["Buttons"]["Register"]["name"]}
             </button>
             <p align="left" width="101%">
               <br></br><br></br><font size="6" color="#8B0000">{textObj && textObj[lang]["PageText"]["LoginPage"][0]}  </font><font color="#003e4d" size="6">
                {textObj && textObj[lang]["PageText"]["LoginPage"][1]}</font>
               <br></br><br></br><br></br>
             </p>
             <button
                style={{cursor: "pointer", fontSize: "42px", paddingTop: "1.8%", paddingBottom: "1.8%",
                 fontWeight: "500", background: "#fff", color: "#003e4d", width: "101%"}}
                align="center"
                className="box1003"
                onClick={(e) => { e.preventDefault();  history.push("/"); }}
                title= {textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["title"]}>
              {textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["name"]}
             </button>
             <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
           </form>
          <br></br>
          <SnackBar
            text={snackbar.text}
            color={snackbar.color}
            lang={lang}
            textObj={textObj}
            isVisible={snackbar.isVisible}
            timeOut={4000}
            setVisible={v => setSnackbar({ ...snackbar, isVisible: v })}
          />
        </td>
       </tr>
      </tbody>
    </table>
  </>
 )
}
