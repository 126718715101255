import React from 'react';
import { useHistory } from "react-router-dom";
import { ROOT_PATH } from '../../constants';

export default function (props) {
  let history = useHistory();
  let icon =  (!props.icon) ? "X" : (props.icon);

  let img = () => {
     if (props.icon === "X")
        return `${ROOT_PATH}/izo/x.png`;
     else if (props.icon === "+")
        return `${ROOT_PATH}/izo/+.png`;
     else if (props.icon === "<")
        return `${ROOT_PATH}/izo/ss.png`;
  }

  return (
    <table align="center" width="90%">
      <tr>
        <td style={{ width: "100%", textAlign: "left" }}> <a style={{cursor: "pointer"}} onClick={() => { history.push(props.navLink); }} title={props.menuTitle} ><font size="7" color="#003e4d">{props.pageTitle}</font> </a></td>
        <td width="100%"></td>
        <td><a style={{cursor: "pointer"}}
               onClick={() => { history.push(props.navLink); if(/address/.test(props.navLink)) { window.location.reload();} }}
              title={props.menuTitle}><img src={img()} border="0"/>
             </a>
        </td>
      </tr>
    </table>
  )
}
