import React from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { TopMenu, BottomMenu, SnackBar, LoginTimeout, SnackbarNoButton} from "../../components";
import { ROOT_PATH, API_PATH } from '../../constants';
import { syncTexts } from "../../util"

export default function (props) {
  let history  = useHistory();
  let token = localStorage.getItem("token");
  if (!token) history.push("/");
  let {h} = useParams();
  if (!h) history.push("/address/all");

  let user       = JSON.parse(localStorage.getItem("user"));
  let addr       = user.addresses.filter(n => n != null && n.address == h).map(x => x);
  let lang       = JSON.parse(localStorage.getItem("lang")) || "en";
  let cachedAddr = JSON.parse(localStorage.getItem(h));
  let txTimeout  = localStorage.getItem("txTimeout") || 0;

  let [textObj, setTextObj]           = React.useState("")
  const [tokenAmount, setTokenAmount] = React.useState("");
  const [snackbar, setSnackbar]       = React.useState({ isVisible: false, text: "", color: "" });
  const [snackbar2, setSnackbar2]     = React.useState({ isVisible: false, text: "", color: "" });
  const [snackbar3, setSnackbar3]     = React.useState({ isVisible: false, text: "", color: "" });

  let sendTx = () => {
    if (!tokenAmount) {
      setSnackbar({
        text:      textObj[lang]["Components"]["Messages"]["sendTokCount"],
        isVisible: true,
        color:     "#2b2b2b",
      });
      return;
   }

   if (addr[0].privateKey === "N/A") {
     setSnackbar({
       text:      textObj[lang]["Components"]["Messages"]["cantSendTx"],
       isVisible: true,
       color:     "#2b2b2b",
     });
     return;
   }

    if (Number.parseInt(cachedAddr.tokens) < Number.parseInt(tokenAmount)) {
     setSnackbar({
       text:      textObj[lang]["Components"]["Messages"]["noFunds"],
       isVisible: true,
       color:     "#2b2b2b",
     });
     return;
   }

   if (txTimeout && txTimeout > new Date().getTime()) {
     setSnackbar({
       text:      textObj[lang]["Components"]["Messages"]["txLimit"],
       isVisible: true,
       color:     "#2b2b2b",
     });
     return;
   }

    let pk = addr[0].privateKey;
    if (pk[0] + pk[1] + "" != "0x")
        addr[0].privateKey = '0x' + addr[0].privateKey


   let redir;

   setSnackbar3({
      text:      textObj[lang]["Components"]["Messages"]["txSent"],
      isVisible: true,
      color:     "#2b2b2b",
   });

  redir = setTimeout(() => {
     history.push("/address/" + h);
     window.location.reload()
  }, 4000);

  let timeout = new Date();
  timeout.setMinutes(timeout.getMinutes() + 1)
  localStorage.setItem("txTimeout", timeout.getTime());

  axios
      .post(`${API_PATH}/token/send`, {
         from:    h,
         amount:  tokenAmount,
         key:     addr[0].privateKey,
         token:   localStorage.getItem("token"),
         lang:    lang,
         login:   user.login,
         password: user.password,
      })
      .then(({ data }) => {
          if (data.status == "success") {
              clearTimeout(redir);
              let txHash = data.tx;
              setSnackbar({isVisible: false });
              setSnackbar2({ text: textObj[lang]["Components"]["Messages"]["TxSuccess"], color: "#2b2b2b", isVisible: true });
          } else {
              clearTimeout(redir);
              setSnackbar({isVisible: false });
              setSnackbar2({ text: data.message, color: "#2b2b2b", isVisible: true });
              console.log(data)
          }
      })
      .catch((err, data) => {
          clearTimeout(redir);
          setSnackbar({isVisible: false});
          setSnackbar2({
            text:      textObj[lang]["Components"]["Messages"]["smartContractExecErr"],
            isVisible: true,
            color:     "#2b2b2b",
          });
          console.log(err)
      });
}

   React.useEffect(async () => {
    if (!textObj) {
      setTextObj(JSON.parse(localStorage.getItem("texts")))
      let t = await syncTexts(); 
      setTextObj(t);
    }
  })


return (
  <>
    <div style={{ minHeight: "1100px" }}>
    <br></br><br></br><br></br>
    <TopMenu
        navLink   = {"/address/" + h}
        pageTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["Send"]["name"]}
        menuTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["Send"]["title"]}
        icon      = "<">
   </TopMenu>
   <table align="center" width="80%">
     <tr>
       <td colspan="3"><br></br><br></br><br></br><br></br><br></br><br></br></td>
     </tr>
     <tr>
       <td align="left"><font size="7" color="#8B0000">{textObj && textObj[lang]["Components"]["FieldTitles"]["TokCount"]}</font></td>
       <td width="100%"></td>
       <td><br></br><br></br><br></br></td>
     </tr>
     <tr>
       <td colspan="3"><br></br></td>
     </tr>
     <tr>
       <td width="60%">
         <font size="7" color="#003e4d">
          <form>
           <input type="text"
              style={{ width: "136%", fontSize: "42px", color: "#003e4d",
               border: "4px solid", paddingTop: "4%",   paddingBottom: "4%",
               paddingLeft: "1%"}}
              value={tokenAmount}
              onChange={(e) => {
                if (/[^0-9]/.test(e.target.value))
                  setTokenAmount("")
                else {
                  setTokenAmount(e.target.value)
                }
              }
             }
          />
         </form>
        </font>
       </td>
       <td width="20%"></td>
       <td width="20%"></td>
     </tr>
     <tr>
       <td colspan="3"><br></br></td>
     </tr>
   </table>
   <table align="center" width="80%">
    <tr>
      <td colspan="3"><br></br></td>
    </tr>
    <tr>
      <td style={{cursor: "pointer"}} align="center" onClick={() => { sendTx(); }} class="box1003" width="100%" title={textObj && textObj[lang]["Components"]["Buttons"]["Send"]["title"]}>
        <a title={textObj && textObj[lang]["Components"]["Buttons"]["Send"]["title"]}><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["Send"]["name"]}</font></a>
      </td>      
      <SnackBar
         lang={lang}
         textObj={textObj}
         text={snackbar.text}
         isVisible={snackbar.isVisible}
         style={{marginLeft: "5%"}}
         setVisible={v => setSnackbar({ ...snackbar, isVisible: v })}
      />
      <SnackBar
         lang={lang}
         textObj={textObj}
         text={snackbar2.text}
         isVisible={snackbar2.isVisible}
         style={{marginLeft: "5%"}}
         redir={"/address/" + h}
         setVisible={v => setSnackbar({ ...snackbar2, isVisible: v })}
      />
      <SnackbarNoButton
         lang={lang}
         textObj={textObj}
         text={snackbar3.text}
         isVisible={snackbar3.isVisible}
         style={{marginLeft: "5%"}}
         timeout={4000}
         setVisible={v => setSnackbar({ ...snackbar3, isVisible: v })}
      />
      <td width="20%"></td>
      <td width="20%"></td>
    </tr>
    <br></br><br></br>
    <tr align="left">
      <td colspan="3">
         <font size="6" color="#8B0000">
          {textObj && textObj[lang]["PageText"]["SendTokenPage"][0]}
          <br></br>{textObj && textObj[lang]["PageText"]["SendTokenPage"][1]}
          <br></br>{textObj && textObj[lang]["PageText"]["SendTokenPage"][2]}
          <br></br>{textObj && textObj[lang]["PageText"]["SendTokenPage"][3]}
         </font>
      </td>
    </tr>
    <tr>
      <td colspan="3"><br></br><br></br><br></br><br></br></td>
    </tr>
  </table>
  </div>
 <BottomMenu lang={lang} textObj={textObj}/>
 <LoginTimeout/>
 </>
)
}
