import React from "react";
import { useHistory } from "react-router-dom";
import { TopMenu, BottomMenu, LoginTimeout } from "../../components";
import { ROOT_PATH } from '../../constants';
import { syncTexts } from "../../util"

export default function (props) {
  let history = useHistory();
  let lang    = JSON.parse(localStorage.getItem("lang")) || "en";
  let [textObj, setTextObj] = React.useState("");

  React.useEffect(async () => {
    if (!textObj) {
      setTextObj(JSON.parse(localStorage.getItem("texts")))
      let t = await syncTexts(); 
      setTextObj(t);
    }
  })

  return (
    <>
     <div style={{ minHeight: "100%" }}>
     <br></br><br></br>
      <TopMenu
            navLink   = "/"
            pageTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["Manual"]["name"]}
            menuTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["Manual"]["title"]}
            icon      = "<">
      </TopMenu>
<table align="center" width="80%">
  <tr>
    <td>
      <br></br>
    </td>
  </tr>
  <tr align="left">
    <td colspan="3"><font size="6" color="#003e4d"><br></br>
            <font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][0]}</font>
      <br></br>
      <br></br>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][1]}
      <br></br>
      <br></br>
      <br></br> <a href="http://new-economy-of-consumption.info/" target="blanc" title={textObj && textObj[lang]["PageText"]["ManualPage"][2]}>{textObj && textObj[lang]["PageText"]["ManualPage"][2]} <font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][3]}</font><br></br><font size="5">{textObj && textObj[lang]["PageText"]["ManualPage"][4]}</font></a>
      <br></br>
      <br></br>
      <br></br>{textObj && textObj[lang]["PageText"]["ManualPage"][5]}
      <br></br>
      <br></br>
      <br></br> <font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][6]}</font>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][7]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][8]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][9]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][10]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][11]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][12]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][13]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][14]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][15]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][16]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][17]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][18]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][19]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][20]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][21]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][22]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][23]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][24]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][25]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][26]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][27]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][28]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][29]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][30]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][31]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][32]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][33]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][34]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][35]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][36]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][37]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][38]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][39]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][40]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][41]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][42]}</font>
      <br></br>  {textObj && textObj[lang]["PageText"]["ManualPage"][43]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][44]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][45]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][46]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][47]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][48]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][49]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][50]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][51]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][52]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][53]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][54]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][55]} 
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][56]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][57]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][58]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][59]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][60]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][61]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][62]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][63]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][64]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][65]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][66]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][67]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][68]}</font>
      <br></br>
      <br></br>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][69]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][70]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][71]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][72]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][73]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][74]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][75]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][76]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][77]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][78]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][79]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][80]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][81]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][82]}
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][83]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][84]}
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][85]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][86]}
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][87]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][88]}
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][89]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][90]}
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][91]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][92]}
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][93]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][94]}
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][95]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][96]}
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][97]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][98]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][99]}</font>
      <br></br>
      <br></br>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][100]}
      <br></br>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][101]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][102]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][103]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][104]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][105]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][106]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][107]}
      <br></br>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][108]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][109]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][110]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][111]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][112]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][113]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][114]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][115]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][116]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][117]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][118]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][119]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][120]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][121]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][122]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][123]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][124]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][125]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][126]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][127]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][128]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][129]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][130]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][131]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][132]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][133]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][134]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][135]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][136]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][137]}
      <br></br>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][138]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][139]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][140]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][141]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][142]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][143]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][144]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][145]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][146]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][147]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][148]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][149]}
      <br></br>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][150]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][151]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][152]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][153]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][154]}
      <br></br>
      <br></br><font color="#8B0000">
     {textObj && textObj[lang]["PageText"]["ManualPage"][155]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][156]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][157]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][158]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][159]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][160]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][161]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][162]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][163]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][164]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][165]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][166]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][167]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][168]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][169]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][170]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][171]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][172]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][173]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][174]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][175]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][176]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][177]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][178]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][179]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][180]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][181]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][182]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][183]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][184]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][185]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][186]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][187]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][188]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][189]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][190]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][191]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][192]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][193]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][194]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][195]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][196]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][197]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][198]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][199]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][200]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][201]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][202]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][203]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][204]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][205]}</font>
      <br></br>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][206]}
      <br></br>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][207]}
      <br></br>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][208]}
      <br></br>
      <br></br>{textObj && textObj[lang]["PageText"]["ManualPage"][209]}
      <font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][210]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][211]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][212]}
      <br></br>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][213]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][214]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][215]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][216]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][217]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][218]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][219]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][220]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][221]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][222]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][223]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][224]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][225]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][226]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][227]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][228]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][229]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][230]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][231]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][232]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][233]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][234]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][235]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][236]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][237]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][238]}
      <font color="#8B0000"> {textObj && textObj[lang]["PageText"]["ManualPage"][239]} </font> 
      {textObj && textObj[lang]["PageText"]["ManualPage"][240]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][241]} </font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][242]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][243]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][244]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][245]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][246]}</font>
      <br></br>{textObj && textObj[lang]["PageText"]["ManualPage"][247]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][248]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][249]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][250]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][251]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][252]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][253]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][254]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][255]}</font> 
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][256]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][257]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][258]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][259]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][260]}
      <font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][261]}</font> 
      {textObj && textObj[lang]["PageText"]["ManualPage"][262]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][263]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][264]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][265]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][266]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][267]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][268]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][269]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][270]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][271]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][272]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][273]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][274]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][275]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][276]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][277]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][278]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][279]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][280]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][281]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][282]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][283]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][284]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][285]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][286]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][287]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][288]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][289]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][290]}
      <br></br>
      <br></br>{textObj && textObj[lang]["PageText"]["ManualPage"][291]}
      <br></br>{textObj && textObj[lang]["PageText"]["ManualPage"][292]}
      <br></br>{textObj && textObj[lang]["PageText"]["ManualPage"][293]}
      <br></br>{textObj && textObj[lang]["PageText"]["ManualPage"][294]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][295]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][296]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][297]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][298]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][299]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][300]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][301]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][302]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][303]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][304]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][305]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][306]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][307]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][308]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][309]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][310]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][311]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][312]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][313]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][314]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][315]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][316]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][317]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][318]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][319]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][320]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][321]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][322]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][323]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][324]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][325]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][326]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][327]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][328]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][329]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][330]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][331]} <font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][332]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][333]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000"> {textObj && textObj[lang]["PageText"]["ManualPage"][334]} </font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][335]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][336]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][337]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][338]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][339]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][340]}
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][341]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][342]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][343]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][344]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000"> {textObj && textObj[lang]["PageText"]["ManualPage"][345]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][346]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][347]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][348]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][349]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][350]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][351]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][352]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][353]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][354]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][355]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][356]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][357]}
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][358]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][359]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][360]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][361]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][362]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][363]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][364]}
      <br></br>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][365]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][366]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][367]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][368]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][369]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][370]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][371]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][372]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][373]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][374]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][375]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][376]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][377]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][378]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][379]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][380]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][381]}
      <br></br>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][382]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][383]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][384]}
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][385]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][386]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][387]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][388]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][389]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][390]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][391]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][392]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][393]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][394]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][395]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][396]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][397]}</font> {textObj && textObj[lang]["PageText"]["ManualPage"][398]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][399]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][400]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][401]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][402]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][403]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][404]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][405]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][406]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][407]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][408]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][409]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][410]}
      <font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][411]}</font>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][412]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][413]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][414]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][415]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][416]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][417]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][418]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][419]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][420]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][421]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][422]}</font>
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][423]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][424]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][425]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][426]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][427]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][428]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][429]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][430]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][431]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][432]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][433]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][434]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][435]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][436]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][437]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][438]}
      <br></br>
      <br></br><font color="#8B0000">
      {textObj && textObj[lang]["PageText"]["ManualPage"][439]}</font>
      <br></br> {textObj && textObj[lang]["PageText"]["ManualPage"][440]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][441]} </font> 
      {textObj && textObj[lang]["PageText"]["ManualPage"][442]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][443]} </font>
      <br></br>
      <br></br>
      <br></br>{textObj && textObj[lang]["PageText"]["ManualPage"][444]}
      <br></br>
      <br></br>
      <br></br><font color="#8B0000">{textObj && textObj[lang]["PageText"]["ManualPage"][445]}</font>
      <br></br>
      <br></br>
      <br></br>{textObj && textObj[lang]["PageText"]["ManualPage"][446]}
            <br></br><br></br>

            <br></br><br></br>
            

                </font><br></br>
              </td>
            </tr>
          <tr>
            <td><br></br><br></br><br></br><br></br></td>
         </tr>
      </table>
      <table align="center" width="80%">
           <tr>
              <td align="center" style={{cursor: "pointer"}} class="box1003" width="100%" onClick={() => { history.push("/form"); }} title={textObj && textObj[lang]["Components"]["Buttons"]["SendMessage"]["title"]}>
                 <a title=""><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["SendMessage"]["name"]}</font> </a>
              </td>
           </tr><br></br><br></br>
           <tr>
              <td align="center" class="box1003" style={{cursor:"pointer"}} width="100%" onClick={() => { history.push("/"); }} title={textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["title"]}>
                <a onClick={() => { history.push("/"); }} title="Стартовая страница"><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["name"]}</font> </a>
              </td>
           </tr><br></br><br></br>
            <tr>
              <td align="center" class="box1003" style={{cursor:"pointer"}} width="100%" onClick={() => { window.location.replace("https://www.youtube.com/results?search_query=QUADRATE+Ecosystem"); }} title="YouTube">
                <a onClick={() => { history.push("/"); }} title="YouTube"><font size="7" color="#003e4d">YouTube</font> </a>
              </td>
            </tr>
           <tr><td colspan="3"><br></br><br></br><br></br><br></br></td></tr>
        </table>
        <BottomMenu lang={lang} textObj={textObj}/>
       </div>
       <LoginTimeout/>
      </>
    )
}
