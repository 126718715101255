import React from "react";
import ReactDOM from 'react-dom';
import { useHistory } from "react-router-dom";
import { TopMenu, BottomMenu, CryptoBoard, LoginTimeout} from "../../components";
import { syncTexts } from "../../util"

export default function (props) {
    let history = useHistory();
    let lang = JSON.parse(localStorage.getItem("lang")) || "en";
    let [textObj, setTextObj] = React.useState("")

    React.useEffect(async () => {
      if (!textObj) {
        setTextObj(JSON.parse(localStorage.getItem("texts")))
        let t = await syncTexts(); 
        setTextObj(t);
      }
    })

    return (
      <>
         <br></br><br></br>
         <TopMenu
              navLink   = "/"
              pageTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["Info"]["name"]}
              menuTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["Info"]["title"]}
              icon      = "X">
         </TopMenu>
         <div style={{ minHeight: "100%" }}>
         <table align="center" width="80%">
           <tr align="left">
              <td colspan="3"><font size="6" color="#003e4d">
                 <br></br><br></br> {textObj && textObj[lang]["PageText"]["InfoPage"][0]}
                 </font>
              </td>
           </tr>
           <CryptoBoard lang={lang} textObj={textObj}/>
           <tr>
              <td align="center" class="box1003" style={{cursor:"pointer"}} width="100%" onClick={() => { window.location.reload(); }} title={textObj && textObj[lang]["Components"]["Buttons"]["Refresh"]["title"]}>
                 <a onClick={() => { window.location.reload();}} title={textObj && textObj[lang]["Components"]["Buttons"]["Refresh"]["title"]}><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["Refresh"]["name"]}</font> </a>
              </td>
           </tr>
           <br></br><br></br>
           <tr>
              <td align="center" class="box1003" style={{cursor:"pointer"}} width="100%" onClick={() => { history.push("/currency/conv"); window.location.reload();}} title={textObj && textObj[lang]["Components"]["Buttons"]["ChangeCurrencies"]["title"]}>
                 <a onClick={() => { history.push("/currency/conv"); }} title={textObj && textObj[lang]["Components"]["Buttons"]["ChangeCurrencies"]["title"]}><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["ChangeCurrencies"]["name"]}</font> </a>
              </td>
           </tr><br></br><br></br>
           <tr>
              <td align="center" style={{cursor: "pointer"}} class="box1003" width="100%" onClick={() => { history.push("/form"); }} title={textObj && textObj[lang]["Components"]["Buttons"]["SendMessage"]["title"]}>
                 <a title=""><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["SendMessage"]["name"]}</font> </a>
              </td>
           </tr><br></br><br></br>
           <tr>
              <td align="center" class="box1003" style={{cursor:"pointer"}} width="100%" onClick={() => { history.push("/"); }} title={textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["title"]}>
                <a onClick={() => { history.push("/"); }} title={textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["title"]}><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["name"]}</font> </a>
              </td>
           </tr><br></br><br></br>
            <tr>
              <td align="center" class="box1003" style={{cursor:"pointer"}} width="100%" onClick={() => { window.location.replace("https://www.youtube.com/results?search_query=QUADRATE+Ecosystem"); }} title="YouTube">
                <a onClick={() => { history.push("/"); }} title="YouTube"><font size="7" color="#003e4d">YouTube</font> </a>
              </td>
            </tr>
           <tr><td colspan="3"><br></br><br></br><br></br><br></br></td></tr>
        </table>
        <BottomMenu lang={lang} textObj={textObj}/>
       </div>
       <LoginTimeout/>
      </>
    )
}
