import React from "react";
import ReactDOM from 'react-dom';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { TopMenu, BottomMenu, SnackBar, LoginTimeout } from "../../components";
import { ROOT_PATH, API_PATH, CryptBaseCur, Currencies } from '../../constants';
import { getAllCurrencies } from "../../util";
import { syncTexts } from "../../util"

export default function (props) {
    let history = useHistory();
    let lang    = JSON.parse(localStorage.getItem("lang")) || "en";
    let curr    = JSON.parse(localStorage.getItem("currencies"));
    let curr2   = JSON.parse(localStorage.getItem("currencies2"));
    let mcurr   = JSON.parse(localStorage.getItem("mainCurrency"));
    let {h}     = useParams();
    if (!h) history.push("/address/all");

    let [textObj, setTextObj]           = React.useState("")
    let [currencies, setCurrencies]     = React.useState();
    let [currencies2, setCurrencies2]   = React.useState();
    let [snackbar, setSnackbar]         = React.useState({ isVisible: false, text: "", color: "" });
    let [mainCurrency, setMainCurrency] = React.useState(mcurr ? mcurr.mainCurrency : "USD");
    let [currency1, setCurrency1]       = React.useState(curr ? curr.currency1 : "USD");
    let [currency2, setCurrency2]       = React.useState(curr ? curr.currency2 : "EUR");
    let [currency3, setCurrency3]       = React.useState(curr ? curr.currency3 : "RUR");
    let [currency4, setCurrency4]       = React.useState(curr2 ? curr2.currency4 : "USD");
    let [currency5, setCurrency5]       = React.useState(curr2 ? curr2.currency5 : "EUR");
    let updateCurrencies;

    if (h == "board") {
      updateCurrencies = () => {
        if (currency1 == currency2
          || currency2 == currency3
          || currency1 == currency3)
        {
          setSnackbar({ isVisible: true, text: textObj[lang]["Components"]["Messages"]["threeDifferentCurrencies"], color: "#2b2b2b" });
          return;
        }

        localStorage.setItem("currencies", JSON.stringify({ currency1, currency2, currency3 }));
        setSnackbar({ isVisible: true, text: textObj[lang]["Components"]["Messages"]["changesMade"], color: "#2b2b2b" });
      }
    } else if (h == "conv") {
        updateCurrencies = () => {
          if (currency4 == currency5) {
             setSnackbar({ isVisible: true, text: textObj[lang]["Components"]["Messages"]["twoDifferentCurrencies"], color: "#2b2b2b" });
             return;
          }

          localStorage.setItem("currencies2", JSON.stringify({ currency4, currency5 }));
          setSnackbar({ isVisible: true, text: textObj[lang]["Components"]["Messages"]["changesMade"], color: "#2b2b2b" });
        }
    } else if (h == "main") {
       let token = localStorage.getItem("token");
       if (!token) history.push("/");

       updateCurrencies = () => {
          localStorage.setItem("mainCurrency", JSON.stringify({ mainCurrency }));
          setSnackbar({ isVisible: true, text: textObj[lang]["Components"]["Messages"]["changesMade"], color: "#2b2b2b" });
       }
    }


    let showMainList = () => {
      let el = document.getElementById("curList1")

      if (el.style.display !== "none")
        el.style.display = "none"
      else 
        el.style.display = "inherit"
    }

    let showList1 = () => {
      let el = document.getElementById("curList1")
      let el2 = document.getElementById("curList2")
      let el3 = document.getElementById("curList3")

      if (el.style.display !== "none")
        el.style.display = "none"
      else {
        el.style.display = "inherit"
        if (el2 && el2 != null) el2.style.display = "none"
        if (el3 && el3 != null) el3.style.display = "none"
      }
    }

    let showList2 = () => {
      let el = document.getElementById("curList2")
      let el2 = document.getElementById("curList3")
      let el3 = document.getElementById("curList1")
      
      if (el.style.display !== "none")
        el.style.display = "none"
      else {
        el.style.display = "inherit"
        if (el2 && el2 != null) el2.style.display = "none"
        if (el3 && el3 != null) el3.style.display = "none"
      }
    }

    let showList3 = () => {
      let el = document.getElementById("curList3")
      let el2 = document.getElementById("curList2")
      let el3 = document.getElementById("curList1")
      
      if (el.style.display !== "none")
        el.style.display = "none"
      else {
        el.style.display = "inherit"
        if (el2 && el2 != null) el2.style.display = "none"
        if (el3 && el3 != null) el3.style.display = "none"
      }
    }

    React.useEffect(async () => {
      if (!textObj) {
        setTextObj(JSON.parse(localStorage.getItem("texts")))
        let t = await syncTexts(); 
        setTextObj(t);
      }
    })
    
    window.onload = async () => {
      setCurrencies(await getAllCurrencies())
    }

    return (
      <>
      <div style={{ minHeight: "1100px" }}>
      <br></br><br></br>
      <TopMenu
           navLink   = {(h != "main") ? "/info" : "/settings"}
           pageTitle = {(h != "main") ? textObj && textObj[lang]["Components"]["TopMenu"]["ChangeInfoCurrency"]["name"] : textObj && textObj[lang]["Components"]["TopMenu"]["ChangeMainCurrency"]["name"]}
           menuTitle = {(h != "main") ? textObj && textObj[lang]["Components"]["TopMenu"]["ChangeInfoCurrency"]["title"] : textObj && textObj[lang]["Components"]["TopMenu"]["ChangeMainCurrency"]["title"]}
           icon      = "<">
      </TopMenu>
      <table align="center" width="80%">
        <tr>
          <td colspan="3"><br></br><br></br></td>
        </tr>
        <tr style={{position: "relative"}} align="left">
        {
          (h === "main") && (
            <table width="100%">
             <tr>
               <td>
                  <font size="7" color="#8B0000"><br></br>1</font><font size="7" color="#003e4d">&nbsp;=&nbsp;</font>
                  <nav style={{cursor: "pointer"}}>
                   <ul onClick={() => {showMainList();}}                   
                       className="form-control">
                      <li key={mainCurrency}>
                        <a style={{backgroundImage: "url(" +  ROOT_PATH + "/flags_64/" + Currencies.get(mainCurrency) + ")", 
                                  backgroundRepeat: "no-repeat"}}
                        >{mainCurrency}
                        </a>
                      </li>
                        <ul id="curList1" style={{display: "none"}}>
                        {                      
                          currencies && currencies.map((object, i) => {
                            for (let k in object) {
                              if (object.hasOwnProperty(k)) {
                                if (k === "name") {                
                                  return mainCurrency != object[k] && (
                                    <li onClick={(e) => setMainCurrency(object[k])} key={object[k]}>                                                                      
                                      <a
                                        style={{backgroundImage: "url(" +  ROOT_PATH + "/flags_64/" + Currencies.get(object[k]) + ")", 
                                              backgroundRepeat: "no-repeat"}}
                                      >{object[k]}
                                      </a>
                                    </li>
                                  )    
                                }
                              }
                            }              
                          })
                        }
                      </ul>
                  </ul>
                </nav>
               </td>
             </tr>
           </table>
        )}
        {
          (h !== "main") && (
            <table width="100%">
             <tr>
               <td>
                  <font size="7" color="#8B0000"><br></br>1</font><font size="7" color="#003e4d">&nbsp;=&nbsp;</font>
                  <nav style={{cursor: "pointer"}}>
                   <ul onClick={() => {showList1();}}                   
                       className="form-control">
                      <li key={(h == "conv") ? currency4 : currency1}>
                        <a style={{backgroundImage: "url(" +  ROOT_PATH + "/flags_64/" + Currencies.get((h == "conv") ? currency4 : currency1) + ")", 
                                  backgroundRepeat: "no-repeat"}}
                        >{(h == "conv") ? currency4 : currency1}
                        </a>
                      </li>
                        <ul id="curList1" style={{display: "none"}}>
                        {                      
                          currencies && currencies.map((object, i) => {
                            for (let k in object) {
                              if (object.hasOwnProperty(k)) {
                                if (k === "name") {                
                                  return ((h == "conv") ? currency4 : currency1) != object[k] && (
                                    <li onClick={(e) => (h == "conv") ? setCurrency4(object[k]) : setCurrency1(object[k])} key={object[k]}>                                                                      
                                      <a
                                        style={{backgroundImage: "url(" +  ROOT_PATH + "/flags_64/" + Currencies.get(object[k]) + ")", 
                                              backgroundRepeat: "no-repeat"}}
                                      >{object[k]}
                                      </a>
                                    </li>
                                  )    
                                }
                              }
                            }              
                          })
                        }
                      </ul>
                  </ul>
                </nav>
               </td>
             </tr>
           </table>
         )}
         {
         (h == "conv" || h == "board") && (
          <table width="100%">
            <br></br>
            <tr>
              <td>
              <font size="7" color="#8B0000"><br></br>2</font><font size="7" color="#003e4d">&nbsp;=&nbsp;</font>
                <nav style={{cursor: "pointer"}}>
                   <ul onClick={() => {showList2();}}                   
                       className="form-control">
                      <li key={(h == "conv") ? currency5 : currency2}>
                        <a style={{backgroundImage: "url(" +  ROOT_PATH + "/flags_64/" + Currencies.get((h == "conv") ? currency5 : currency2) + ")", 
                                  backgroundRepeat: "no-repeat"}}
                        >{(h == "conv") ? currency5 : currency2}
                        </a>
                      </li>
                        <ul id="curList2" style={{display: "none"}}>
                        {                      
                          currencies && currencies.map((object, i) => {
                            for (let k in object) {
                              if (object.hasOwnProperty(k)) {
                                if (k === "name") {                
                                  return ((h == "conv") ? currency5 : currency2) != object[k] && (
                                    <li onClick={(e) => (h == "conv") ? setCurrency5(object[k]) : setCurrency2(object[k])} key={object[k]}>                                                                      
                                      <a
                                        style={{backgroundImage: "url(" +  ROOT_PATH + "/flags_64/" + Currencies.get(object[k]) + ")", 
                                              backgroundRepeat: "no-repeat"}}
                                      >{object[k]}
                                      </a>
                                    </li>
                                  )    
                                }
                              }
                            }              
                          })
                        }
                      </ul>
                  </ul>
                </nav>
              </td>
            </tr>
         </table>
       )}
       { h == "board" && (
         <table width="100%">
           <br></br>
           <tr>
             <td>
            <font size="7" color="#8B0000"><br></br>3</font><font size="7" color="#003e4d">&nbsp;=&nbsp;</font>
                <nav style={{cursor: "pointer"}}>
                   <ul onClick={() => {showList3();}}                   
                       className="form-control">
                      <li key={currency3}>
                        <a style={{backgroundImage: "url(" +  ROOT_PATH + "/flags_64/" + Currencies.get(currency3) + ")", 
                                  backgroundRepeat: "no-repeat"}}
                        >{currency3}
                        </a>
                      </li>
                        <ul id="curList3" style={{display: "none"}}>
                        {                      
                          currencies && currencies.map((object, i) => {
                            for (let k in object) {
                              if (object.hasOwnProperty(k)) {
                                if (k === "name") {                
                                  return currency3 != object[k] && (
                                    <li onClick={(e) => setCurrency3(object[k])} key={object[k]}>                                                                      
                                      <a
                                        style={{backgroundImage: "url(" +  ROOT_PATH + "/flags_64/" + Currencies.get(object[k]) + ")", 
                                              backgroundRepeat: "no-repeat"}}
                                      >{object[k]}
                                      </a>
                                    </li>
                                  )    
                                }
                              }
                            }              
                          })
                        }
                      </ul>
                  </ul>
                </nav>
              </td>
            </tr>
         </table>
       )}
        </tr>
        <tr>
          <td colspan="3"><br></br><br></br><br></br><br></br></td>
        </tr>
        <tr>
          <td
            align="center"
            class="box1003"
            style={{cursor: "pointer"}}
            width="100%"
            onClick={() => updateCurrencies()}
            title={textObj && textObj[lang]["Components"]["Buttons"]["Apply"]["title"]}>
              <a title={textObj && textObj[lang]["Components"]["Buttons"]["Apply"]["title"]}>
                <font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["Apply"]["name"]}</font>
              </a>
          </td>
        </tr>
        <br></br><br></br>
        <tr>
           <td align="center" class="box1003" style={{cursor:"pointer"}} width="100%" onClick={() => { history.push("/"); }} title={textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["title"]}>
             <a onClick={() => { history.push("/"); }} title={textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["title"]}><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["name"]}</font> </a>
           </td>
           <td width="20%"></td>
        </tr>
        <tr>
          <td colspan="3"><br></br><br></br></td>
        </tr>
      </table>
      </div>
      <SnackBar
        text={snackbar.text}
        color={snackbar.color}
        lang={lang} 
        textObj={textObj}
        isVisible={snackbar.isVisible}
        redir={"/info"}
        setVisible={v => setSnackbar({ ...snackbar, isVisible: v })}
      />
     <br></br><br></br><br></br><br></br><br></br>
    <BottomMenu lang={lang} textObj={textObj}/>
   <LoginTimeout/>
  </>
 )
}
