import React, { Component }  from "react";
import { useHistory } from "react-router-dom";

export default function (props) {
  let history = useHistory();
  let [timer, setTimer] = React.useState(900);
  let interval;

  React.useEffect(() => {
   const interval = setInterval(() => {
     setTimer(timer - 10);
   }, 10000);

   return () => clearInterval(interval);
  });

  if (timer === 0) {
    localStorage.setItem("token", "");
    history.push("/");
    setTimer(900);
  }

  document.body.onmousemove = () => {
    setTimer(900);
  }

  document.body.onscroll = () => {
    setTimer(900);
  }

  document.body.onclick = () => {
    setTimer(900);
  }

  document.body.onkeypress = () => {
    setTimer(900);
  }

  document.body.onmousedown = () => {
    setTimer(900);
  }

  return (
      <React.Fragment>{props.children}</React.Fragment>
  )
}
