export default new Map([
  ['USD','us.png'],
  ['EUR','eu.png'],
  ['GBP','uk.png'],
  ['RUR','ru.png'],
  ['HUF','hu.png'],
  ['HKD','hk.png'],
  ['CZK','cz.png'],
  ['HUF','hu.png'],
  ['AUD','au.png'],
  ['AZN','az.png'],
  ['HUF','hu.png'],
  ['AMD','am.png'],
  ['BYN','by_.png'],
  ['BGN','bg.png'],
  ['BRL','br.png'],
  ['INR','in.png'],
  ['KZT','kz.png'],
  ['CAD','ca.png'],
  ['DKK','dk.png'],
  ['KGS','kg.png'],
  ['CNY','cn.png'],
  ['MDL','md.png'],
  ['NOK','no.png'],
  ['PLN','pl.png'],
  ['RON','ro.png'],
  ['TJS','tj.png'],
  ['TRY','tr.png'],
  ['UZS','uz.png'],
  ['UAH','ua.png'],
  ['SGD','sg.png'],
  ['JPY','jp.png'],
  ['SEK','se.png'],
  ['KRW','ki.png'],
  ['CHF','ch.png'],
  ['TMT','tm.png'],
  ['ZAR','za.png'],
])
