import React from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { TopMenu, BottomMenu, SnackBar, LoginTimeout, SnackbarNoButton } from "../../components";
import { ROOT_PATH, API_PATH } from '../../constants';
import { syncTexts } from "../../util"

export default function (props) {
  let history = useHistory();
  let token = localStorage.getItem("token");
  if (!token) history.push("/");
  let {h} = useParams();
  if (!h) history.push("/address/all");

  let user      = JSON.parse(localStorage.getItem("user"));
  let addr      = user.addresses.filter(n => n != null && n.address == h).map(x => x);
  let txTimeout = localStorage.getItem("txTimeout") || 0;
  let lang      = JSON.parse(localStorage.getItem("lang")) || "en";

  const [tokenAmount, setTokenAmount] = React.useState("");
  const [ethAmount, setEthAmount]     = React.useState("");
  const [pubKey, setPubKey]           = React.useState("");
  let [textObj, setTextObj]           = React.useState("");
  let [passwd, setPasswd]             = React.useState("");

  const [snackbar, setSnackbar]   = React.useState({ isVisible: false, text: "", color: "" });
  const [snackbar2, setSnackbar2] = React.useState({ isVisible: false, text: "", color: "" });
  const [snackbar3, setSnackbar3] = React.useState({ isVisible: false, text: "", color: "" });

  let sendTx = () => {
    if (!tokenAmount && !ethAmount) {
      setSnackbar({
        text:      textObj[lang]["Components"]["Messages"]["tokOrEthSend"],
        isVisible: true,
        color:     "#2b2b2b",
      });
      return;
   }

   if (addr[0].privateKey === "N/A") {
     setSnackbar({
       text:      textObj[lang]["Components"]["Messages"]["cantSendTx"],
       isVisible: true,
       color:     "#2b2b2b",
     });
     return;
   }

   if (tokenAmount && ethAmount) {
     setSnackbar({
       text:      textObj[lang]["Components"]["Messages"]["tokOrEthSend"],
       isVisible: true,
       color:     "#2b2b2b",
     });
     return;
   }

   if (!pubKey) {
     setSnackbar({
       text:      textObj[lang]["Components"]["Messages"]["tokOrEthSend"],
       isVisible: true,
       color:     "#2b2b2b",
     });
     return;
   }

   if (pubKey.length > 44 || pubKey.length < 40) {
     setSnackbar({
       text:      textObj[lang]["Components"]["Messages"]["tokOrEthSend"],
       isVisible: true,
       color:     "#2b2b2b",
     });
     return;
   }

   if (!passwd) {
     setSnackbar({
       text:      textObj[lang]["Components"]["Messages"]["tokOrEthSend"],
       isVisible: true,
       color:     "#2b2b2b",
     });
     return;
   }

   if (tokenAmount) {
     if (addr[0].tokens < tokenAmount) {
       setSnackbar({
         text:      textObj[lang]["Components"]["Messages"]["noFunds"],
         isVisible: true,
         color:     "#2b2b2b",
       });
       return;
     }

     if (txTimeout && txTimeout > new Date().getTime()) {
       setSnackbar({
         text:      textObj[lang]["Components"]["Messages"]["txLimit"],
         isVisible: true,
         color:     "#2b2b2b",
       });
       return;
     }

     setSnackbar3({
       text:      textObj[lang]["Components"]["Messages"]["txSent"],
       isVisible: true,
       color:     "#2b2b2b",
     });

     let redir = setTimeout(() => {
       history.push("/address/" + h);
       window.location.reload()
     }, 4000);

     let timeout = new Date();
     timeout.setMinutes(timeout.getMinutes() + 1)
     localStorage.setItem("txTimeout", timeout.getTime());

    let pk = addr[0].privateKey;
    if (pk[0] + pk[1] + "" != "0x")
        addr[0].privateKey = '0x' + addr[0].privateKey

     axios
       .post(`${API_PATH}/token/sendTok`, {
         from:    h,
         to:      pubKey,
         amount:  tokenAmount,
         key:     addr[0].privateKey,
         token:   localStorage.getItem("token"),
         login:   user.login,
         passwd:  user.password,
         trackPasswd: passwd,
         lang: lang
       })
       .then(({ data }) => {
          if (data.status == "success") {
            let txHash = data.tx;
            clearTimeout(redir);
            setSnackbar3({isVisible: false });
            setSnackbar2({ 
              text:      textObj[lang]["Components"]["Messages"]["TxSuccess"],
              color:     "#2b2b2b",
              isVisible: true
            });
          } else {
              clearTimeout(redir);
              setSnackbar3({isVisible: false });
              setSnackbar2({ text: data.message, color: "#2b2b2b", isVisible: true });
              console.log(data)
         }
       })
       .catch((err, data) => {
         console.log(err)
       });
    }

    else if (ethAmount) {
      if (addr[0].ether < ethAmount) {
        setSnackbar({
          text:      textObj[lang]["Components"]["Messages"]["noFunds"],
          isVisible: true,
          color:     "#2b2b2b",
        });
        return;
      }

      if (txTimeout && txTimeout > new Date().getTime()) {
        setSnackbar({
          text:      textObj[lang]["Components"]["Messages"]["txLimit"],
          isVisible: true,
          color:     "#2b2b2b",
        });
        return;
      }

      setSnackbar3({
        text:      textObj[lang]["Components"]["Messages"]["txSent"],
        isVisible: true,
        color:     "#2b2b2b",
      });

      let redir = setTimeout(() => {
        history.push("/address/" + h);
        window.location.reload()
      }, 4000);

      let timeout = new Date();
      timeout.setMinutes(timeout.getMinutes() + 1)
      localStorage.setItem("txTimeout", timeout.getTime());

      let pk = addr[0].privateKey;
      if (pk[0] + pk[1] + "" != "0x")
          addr[0].privateKey = '0x' + addr[0].privateKey

      axios
        .post(`${API_PATH}/token/sendEth`, {
          from:    h,
          to:      pubKey,
          amount:  ethAmount,
          key:     addr[0].privateKey,
          token:   localStorage.getItem("token"),
          login:   user.login,
          passwd:  user.password,
          trackPasswd: passwd,
          lang: lang
        })
        .then(({ data }) => {
           if (data.status == "success") {
             let txHash = data.tx;
             clearTimeout(redir);
             setSnackbar3({isVisible: false });
             setSnackbar2({ 
              text: textObj[lang]["Components"]["Messages"]["TxSuccess"], 
              color: "#2b2b2b", 
              isVisible: true 
            });
           } else {
             setSnackbar3({isVisible: false });
             setSnackbar2({ text: data.message, color: "#2b2b2b", isVisible: true });
             console.log(data)
          }
        })
        .catch((err, data) => {
          clearTimeout(redir);
          setSnackbar3({isVisible: false });
          setSnackbar2({
            text:      textObj[lang]["Components"]["Messages"]["txSent"],
            isVisible: true,
            color:     "#2b2b2b",
          });
          console.log(err)
        });
    }
  }

   React.useEffect(async () => {
    if (!textObj) {
      setTextObj(JSON.parse(localStorage.getItem("texts")))
      let t = await syncTexts(); 
      setTextObj(t);
    }
  })

  return (
   <>
    <div style={{ minHeight: "100%"}}>
    <br></br><br></br><br></br>
    <TopMenu
      navLink   = {"/address/" + h}
      pageTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["Transfer"]["name"]}
      menuTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["Transfer"]["title"]}
      icon      = "<">
    </TopMenu>
    <table align="center" width="80%">
      <tr>
        <td colspan="3"><br></br><br></br><br></br><br></br><br></br><br></br></td>
      </tr>
      <tr>
        <td align="left"><font size="7" color="#8B0000">{textObj && textObj[lang]["Components"]["FieldTitles"]["SetPubKey"]}</font></td>
        <td width="100%"></td>
        <td><br></br><br></br></td>
      </tr>
      <tr>
        <td colspan="3"><br></br></td>
      </tr>
      <tr>
        <td width="80%">
          <font size="7" color="#003e4d">
           <form>
            <input type="text"
               style={{ width: "100%", fontSize: "42px", color: "#003e4d",
                border: "4px solid", paddingTop: "3%",   paddingBottom: "3%",
                paddingLeft: "1%"}}
                value={pubKey}
                onChange={(e) => {
                  if (/[^a-zA-Z0-9]+/.test(e.target.value))
                    setPubKey("")
                  else {
                    setPubKey(e.target.value)
                  }
                 }
               }
            />
          </form>
         </font>
        </td>
        <td width="20%"></td>
        <td width="20%"></td>
      </tr>
      <tr>
        <td colspan="3"><br></br></td>
      </tr>
      <tr>
        <td align="left"><font size="7" color="#8B0000">{textObj && textObj[lang]["Components"]["FieldTitles"]["EtcCount"]}</font></td>
        <td width="100%"></td>
        <td><br></br><br></br></td>
      </tr>
      <tr>
        <td colspan="3"><br></br></td>
      </tr>
      <tr>
        <td width="60%">
          <font size="7" color="#003e4d">
           <form>
            <input type="text"
               style={{ width: "100%", fontSize: "42px", color: "#003e4d",
                border: "4px solid", paddingTop: "3%",   paddingBottom: "3%",
                paddingLeft: "1%"}}
                value={ethAmount}
                onChange={(e) => {
                  if (/[^0-9\.]/.test(e.target.value))
                    setEthAmount("")
                  else {
                    setEthAmount(e.target.value)
                  }
                }
               }
            />
          </form>
         </font>
        </td>
        <td width="20%"></td>
        <td width="20%"></td>
      </tr>
      <tr>
        <td colspan="3"><br></br></td>
      </tr>
      <tr>
        <td align="left"><font size="7" color="#8B0000">{textObj && textObj[lang]["Components"]["FieldTitles"]["TokCount"]}</font></td>
        <td width="100%"></td>
        <td><br></br><br></br></td>
      </tr>
      <tr>
        <td colspan="3"><br></br></td>
      </tr>
      <tr>
        <td width="60%">
          <font size="7" color="#003e4d">
           <form>
            <input type="text"
               style={{ width: "100%", fontSize: "42px", color: "#003e4d",
                border: "4px solid", paddingTop: "3%",   paddingBottom: "3%",
                paddingLeft: "1%"}}
                value={tokenAmount}
                onChange={(e) => {
                  if (/[^0-9]/.test(e.target.value))
                    setTokenAmount("")
                  else {
                    setTokenAmount(e.target.value)
                  }
                }
               }
           />
          </form>
         </font>
        </td>
        <td width="20%"></td>
        <td width="20%"></td>
      </tr>
      <tr>
        <td colspan="3"><br></br></td>
      </tr>
      <tr>
        <td align="left"><font size="7" color="#8B0000">{textObj && textObj[lang]["Components"]["FieldTitles"]["TrackPasswd"]}</font></td>
        <td width="100%"></td>
        <td><br></br><br></br></td>
      </tr>
      <tr>
        <td colspan="3"><br></br></td>
      </tr>
      <tr>
        <td width="60%">
          <font size="7" color="#003e4d">
           <form>
            <input type="text"
               maxlength="4"
               style={{ width: "100%", fontSize: "42px", color: "#003e4d",
                border: "4px solid", paddingTop: "3%",   paddingBottom: "3%",
                paddingLeft: "1%"}}
                value={passwd}
                onChange={(e) => {
                  setPasswd(e.target.value)
                }
               }
           />
          </form>
         </font>
        </td>
        <td width="20%"></td>
        <td width="20%"></td>
      </tr>
      <tr>
        <td colspan="3"><br></br></td>
      </tr>
    </table>
    <table align="center" width="80%">
      <tr>
        <td colspan="3"><br></br></td>
      </tr>
      <tr>
        <td
          align="center"
          class="box1003"
          width="100%"
          style={{cursor: "pointer"}}
          onClick={() => { sendTx(); }}
          title={textObj && textObj[lang]["Components"]["Buttons"]["Transfer"]["title"]}>
            <a title={textObj && textObj[lang]["Components"]["Buttons"]["Transfer"]["title"]}>
              <font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["Transfer"]["name"]}</font>
            </a>
        </td>
        <SnackBar
           lang={lang}
           textObj={textObj}
           text={snackbar.text}
           isVisible={snackbar.isVisible}
           timeOut={4000}
           setVisible={v => setSnackbar({ ...snackbar, isVisible: v })}
        />
        <SnackBar
           lang={lang}
           textObj={textObj}
           text={snackbar2.text}
           isVisible={snackbar2.isVisible}
           timeOut={4000}
           redir={"/address/" + h}
           setVisible={v => setSnackbar({ ...snackbar2, isVisible: v })}
        />
        <SnackbarNoButton
           lang={lang}
           textObj={textObj}
           text={snackbar3.text}
           isVisible={snackbar3.isVisible}
           timeOut={4000}
           setVisible={v => setSnackbar({ ...snackbar3, isVisible: v })}
        />
        <td width="20%"></td>
        <td width="20%"></td>
      </tr>
      <tr>
        <td colspan="3"><br></br><br></br><br></br><br></br></td>
      </tr>
    </table>
   </div>
  <BottomMenu lang={lang} textObj={textObj}/>
 <LoginTimeout/>
</>)

}
