
import React from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import axios from 'axios';
import ReactDOM from 'react-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TopMenu, BottomMenu, SnackBar, LoginTimeout } from "../../components";
import { ROOT_PATH, API_PATH, Months } from '../../constants';
import { timeConverter, updateFiatPrice, updateRUBPrice, getAllCurrencies } from "../../util.js";
import { syncTexts } from "../../util"

export default function (props) {
   let history = useHistory();
   let token   = localStorage.getItem("token");
   let {h}     = useParams();

   if (!token) history.push("/");
   if (!h) history.push("/address/all");

   let lang      = JSON.parse(localStorage.getItem("lang")) || "en";
   let user      = JSON.parse(localStorage.getItem("user"));
   let addrObj   = JSON.parse(localStorage.getItem(h));
   let curr      = JSON.parse(localStorage.getItem("mainCurrency"));
   let currList  = JSON.parse(localStorage.getItem("currList"));
   let addr      = user.addresses && user.addresses.filter(n => n && n.address == h).map(x => x);
   let contract  = JSON.parse(localStorage.getItem("contract"));
   let currency1 = curr ? curr.mainCurrency : "USD";

   if (!user.addresses) window.location.reload();
   if (!addr[0]) window.location.reload();

   // Адрес

   let [textObj, setTextObj]         = React.useState("")
   let [royalty, setRoyalty]         = React.useState(0.0000000000);
   let [tokenAmount, setTokenAmount] = React.useState(0);
   let [ethAmount, setEthAmount]     = React.useState(0.0000);
   let [totalAmount, setTotalAmount] = React.useState(0.0000);
   let [stopDateDay, setStopDateDay] = React.useState("0.");
   let [stopMonth, setStopMonth]     = React.useState("00.");
   let [stopYear, setStopYear]       = React.useState("0000");
   let [fiat, setFiat]               = React.useState({ price: 0, name : "..."});
   let [rubPrice, setRUBPrice]       = React.useState(0);
   let [snackbar, setSnackbar]       = React.useState({ isVisible: false, text: "", color: "" });
   let [totalWithdrawAmount, setTotalWithdrawAmount] = React.useState(0.0000);
   let [passwd, setPasswd]   = React.useState("")
   let [privKey, setPrivKey] = React.useState("")

   let showCreatMsg = (e) => {
     document.getElementById("top_message").style.display = "block"
   }

   let hideCreatMsg = (e) => {
     document.getElementById("top_message").style.display = "none"
   }

   let showPublicKey = () => {
     let elem = React.createElement(
       "font",
        {
          style: { color: "#8B0000" },
          size: "5"
        },
        addr[0].address
     );
     ReactDOM.render(elem, document.querySelector("#pub"));
   }

   let showPrivKey = (e) => {
      axios
        .post(`${API_PATH}/user/getPrivKey`, { 
          address:  h,
          password: passwd,
          login:    user.login,
        })
        .then(({ data }) => {
          if (data.status == "success") {
            setPrivKey(data.privKey)
            let elem = React.createElement(
              "font",
              {
                style: { color: "#8B0000" },
                size: "5"
              },
              data.privKey
            );
            ReactDOM.render(elem, document.querySelector("#priv"));
          } else {
            setSnackbar({
              isVisible: true, 
              text:  "Возможно вы ввели неверный пароль. Повторите попытку", 
            })  
          }
        })
        .catch((err, data) => {
          console.log(err)
        });
   }
   
   let updateAddr = () => {
     axios
        .post(`${API_PATH}/contract/getAddr`, { address: h })
        .then(({ data }) => {
           if (data.status == "success") {
              setRoyalty(data.address.currentDeposit);
              setTokenAmount(data.address.tokenAmount);
              setEthAmount(data.address.ethAmount);
              setTotalAmount(data.address.totalDeposit);
              setTotalWithdrawAmount(data.address.totalWithdraw);

              if (data.address.stopDate != 0) {
                 let d = timeConverter(data.address.stopDate);
                 setStopYear(d[0])
                 setStopMonth(d[1])
                 setStopDateDay(d[2])
              }

              let obj = {
                address:  h,
                deposit:  data.address.currentDeposit,
                tokens:   data.address.tokenAmount,
                ether:    data.address.ethAmount,
                total:    data.address.totalDeposit,
                withdraw: data.address.totalWithdraw,
                stopDate: data.address.stopDate,
              }

              localStorage.setItem(h, JSON.stringify(obj))
           }
        })
        .catch((err, data) => {
          console.log(err)
        });
   }
   
   // Рефералка
   
   let [contractBalance, setContractBalance] = React.useState(0);
   let [curBonus, setCurBonus] = React.useState(0);
   let [refCount, setRefCount] = React.useState(0);
   let [refLink, setRefLink] = React.useState("");
  
   let updateRef = () => {
     axios
       .post(`${API_PATH}/ref/getAll`, { address: h })
       .then(({ data }) => {
          if (data.status == "success") {
             setCurBonus(data.ref.curBonus);
             setRefCount(data.ref.refCount);

             let obj = {
               curBonus: Number.parseInt(data.ref.curBonus),
               refCount: Number.parseInt(data.ref.refCount),
             }

          } else {
             console.log(data)
          }
       })
       .catch((err, data) => {
         console.log(err)
       });
   }

   let getRefLink = () => {
      axios
       .post(`${API_PATH}/user/getRefLink`, { address: h, login: user && user.login })
       .then(({ data }) => {
          if (data.status == "success") {
             setRefLink("#/" + data.refLink);
             let obj = JSON.parse(localStorage.getItem("ref"+h));
             obj.refLink = "#/" + refLink;
             localStorage.setItem("ref"+h, JSON.stringify(obj))
          } else {
             console.log(data)
          }
       })
       .catch((err, data) => {
         console.log(err)
       });
   }

   let getContractBalance = () => {
      axios
       .post(`${API_PATH}/token/getRefBalance`, { address: h })
       .then(({ data }) => {
          if (data.status == "success") {
             setContractBalance(data.balance);
             let obj = JSON.parse(localStorage.getItem("ref"+h));
             obj.contractBalance = refLink;
             localStorage.setItem("ref"+h, JSON.stringify(obj))
          } else {
             console.log(data)
          }
       })
       .catch((err, data) => {
         console.log(err)
       });
   }

   let payBonus = () => {
     let pk = addr[0].privateKey;

     if (curBonus == 0) {
        setSnackbar({
          text: textObj[lang]["Components"]["Messages"]["refNoFunds"],
          isVisible: true,
        });
        return;
     }
     
     if (contractBalance == 0) {
        setSnackbar({
          text: textObj[lang]["Components"]["Messages"]["refStopped"],
          isVisible: true,
        });
        return;
     }

     setSnackbar({
       text: textObj[lang]["Components"]["Messages"]["txSent"],
       isVisible: true,
     });

     let timeout = new Date();
     timeout.setMinutes(timeout.getMinutes() + 1)
     localStorage.setItem("txTimeout", timeout.getTime());

     let reload = setTimeout(() => {
       history.push("/address/" + h);
       window.location.reload()
     }, 4000);

     axios
       .post(`${API_PATH}/ref/payBonus`, {
          address:    h,
          privateKey: pk,
          login:      user.login,
          password:   user.password,
          token:      token,
        })
       .then(({ data }) => {
          if (data.status == "success") {
             let txHash = data.tx;
             clearTimeout(reload);
             setSnackbar({ text: textObj[lang]["Components"]["Messages"]["TxSuccess"] + "\n\n", color: "#2b2b2b", isVisible: true });
             window.location.reload()
          } else {
             clearTimeout(reload);
             setSnackbar({ text: data.message, color: "#2b2b2b", isVisible: true });
             console.log(data)
          }
       })
       .catch((err, data) => {
         clearTimeout(reload);
         setSnackbar({
           text:      textObj[lang]["Components"]["Messages"]["smartContractExecErr"],
           isVisible: true,
           color:     "#2b2b2b",
         });
         console.log(err)
       });
   }

   let showRefLink = () => {
     let elem = React.createElement(
       "font",
       {
         style: { color: "#8B0000" },
         size: "5"
       },
       ROOT_PATH + "/" + refLink
     );
     ReactDOM.render(elem, document.querySelector("#refLink"));
   }
  
   //Загрузка
   
   window.onload = async () => {
      updateAddr()
      if (!currList) currList = await getAllCurrencies()
      let res = await updateRUBPrice()
      let fPrice = await updateFiatPrice(currList, currency1)
      setFiat({ price: res / fPrice, name: currency1 })
      setRUBPrice(res)
      updateRef();
      getContractBalance();
      getRefLink();
   } 

   React.useEffect(async () => {
    if (!textObj) {
      setTextObj(JSON.parse(localStorage.getItem("texts")))
      let t = await syncTexts(); 
      setTextObj(t);
    }
  })

    
  return (
   <>
   <br></br><br></br><br></br>
   <TopMenu navLink   = "/address/all"
            pageTitle = {addr && addr[0].name.substring(0,20)}
            title     = {textObj && textObj[lang]["Components"]["TopMenu"]["Send"]["title"]}
            icon      = "<">
   </TopMenu>
   <div
      className="top_message"
      id="top_message"
      align="center"
      width="50%"
      style={{display: "none", maxHeight: "max-content"}}>
      <br></br>
     {textObj && textObj[lang]["PageText"]["SingleAddressPage"][0]}
      <br></br>  <br></br>      
      <form autocomplete="off">
          <input
              style={{ width: "85%", color: "#003e4d", fontSize: "30px", padding: "2%", background: "bisque",border: "none" }}
              className="box1003"
              type="text"
              id="passwd"
              maxLength="40"
              value={passwd}
              onChange={(e) => setPasswd(e.target.value)}
          />
      </form>
      <br></br>  
      <button
          style={{cursor: "pointer", fontSize: "0.8em", paddingTop: "1.8%", paddingBottom: "1.8%",
            fontWeight: "500", background: "#c45331", color: "#fff", minWidth: "55%",
            paddingLeft: "10%", paddingRight: "10%", borderColor: "#fff",
            border: "3px solid"}}
          align="center"
          className="box1003"
          title={textObj && textObj[lang]["Components"]["Buttons"]["Send"]["name"]}
          onClick={() => { 
            hideCreatMsg();
            showPrivKey();
          }}
      >
        {textObj && textObj[lang]["Components"]["Buttons"]["Send"]["name"]}
      </button>
      <br></br> <br></br> 
      <button
          style={{cursor: "pointer", fontSize: "0.8em", paddingTop: "1.8%", paddingBottom: "1.8%",
            fontWeight: "500", background: "#c45331", color: "#fff", minWidth: "55%",
            paddingLeft: "10%", paddingRight: "10%", borderColor: "#fff",
            border: "3px solid"}}
          align="center"
          className="box1003"
          title={textObj && textObj[lang]["Components"]["Buttons"]["Cancel"]["name"]}
          onClick={() => { 
            hideCreatMsg();
          }}
      >
        {textObj && textObj[lang]["Components"]["Buttons"]["Cancel"]["name"]}
      </button>
      <br></br> 
   </div>
   <table align="center" width="80%">
     <tr align="center">
       <td colspan="3"><font size="7" color="#8B0000"><br></br><br></br>{(royalty.toString().match(/[^0-9\.]+/)) ? 0 :royalty && parseFloat(royalty).toFixed(10) || addrObj && parseFloat(addrObj.deposit).toFixed(10) || parseFloat(0).toFixed(10)}&nbsp;<font color="#003e4d">ЕТC </font></font></td>
     </tr>
     <tr>
       <td colspan="3"><br></br><br></br><br></br><br></br></td>
     </tr>
     <tr>
       <td style={{cursor: "pointer"}} align="center" class="box1003" width="100%" onClick={() => { history.push("/exchange/" + h); window.location.reload(); }} title={textObj && textObj[lang]["Components"]["Buttons"]["Exchange"]["title"]}>
         <a style={{cursor: "pointer"}} title={textObj && textObj[lang]["Components"]["Buttons"]["Exchange"]["title"]}><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["Exchange"]["name"]}</font> </a>
       </td>
       <td width="20%"></td>
     </tr>
     <tr>
       <td colspan="3"><br></br><br></br><br></br></td>
     </tr>
   </table>
   <table align="center" width="80%">
   <tr>
     <td align="left" style={{position: "absolute"}}><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Stat"]["Tokens"]}</font></td>
     <td width="100%"></td>
     <td style={{marginTop: "8%", display: "block"}} align="right">
      <font size="7" color="#8B0000">{tokenAmount ||  addrObj &&  addrObj.tokens || 0}</font>&nbsp;<font size="7" color="#003e4d">ТОК</font><br></br><br></br>
     </td>
   </tr>
   <tr>
     <td align="left" style={{position: "absolute"}}><font size="7" color="#003e4d">Ethereum</font></td>
     <td width="100%"></td>
     <td style={{marginTop: "8%", display: "block"}} align="right">
      <font size="7" color="#8B0000">{ethAmount && parseFloat(ethAmount).toFixed(4) ||  addrObj && parseFloat(addrObj.ether).toFixed(4) || parseFloat(0).toFixed(4)}</font>&nbsp;<font size="7" color="#003e4d">ЕТC</font><br></br><br></br>
     </td>
   </tr>
   <tr>
     <td align="left" style={{position: "absolute"}}><font size="7" color="#003e4d"></font></td>
     <td width="100%"></td>
     <td style={{marginTop: "3%", display: "block"}} align="right">
      <font size="7" color="#8B0000">{fiat.price && ethAmount ? (ethAmount * fiat.price).toFixed(2) : ethAmount && parseFloat(ethAmount).toFixed(2)}</font>&nbsp;<font size="7" color="#003e4d">{currency1}</font><br></br><br></br>
     </td>
   </tr>
 </table>
    <table align="center" width="80%">
       <tr>
          <td colspan="3"><br></br><br></br></td>
       </tr>
       <tr>
          <td style={{cursor: "pointer"}} align="center" class="box1003" width="100%" onClick={() => { history.push("/send_token/" + h); }} title={textObj && textObj[lang]["Components"]["Buttons"]["Send"]["title"]}>
             <a title={textObj && textObj[lang]["Components"]["Buttons"]["Send"]["title"]}><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["Send"]["name"]}</font> </a>
          </td>
          <td width="20%"></td>
      </tr>
      <tr>
         <td colspan="3"><br></br><br></br></td>
      </tr>
   </table>
   <table align="center" width="80%">
      <tr>
         <td align="left" style={{position: "absolute"}}><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Stat"]["Accrued"]}</font></td>
         <td width="100%"></td>
         <td style={{marginTop: "7.5%", display: "block"}} align="right">
            <font size="7" color="#8B0000">
              {parseFloat(fiat.price && fiat.price * (totalAmount || addrObj && addrObj.totalAmount) || 0).toFixed(2)}
            </font>&nbsp;
            <font size="7" color="#003e4d">{currency1}</font><br></br><br></br>
         </td>
      </tr>
      <tr>
         <td align="left" style={{position: "absolute"}}><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Stat"]["Received"]}</font></td>
         <td width="100%"></td>
         <td style={{marginTop: "7.5%", display: "block"}} align="right">
            <font size="7" color="#8B0000">
               {parseFloat(fiat.price && fiat.price * (totalWithdrawAmount || addrObj && addrObj.totalWithdrawAmount) || 0).toFixed(2)}
            </font>&nbsp;
            <font size="7" color="#003e4d">{currency1}</font><br></br><br></br>
        </td>
      </tr>
      <tr>
         <td align="left" style={{position: "absolute"}}><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Stat"]["EndDate"]}</font></td>
         <td width="100%"></td>
         <td style={{marginTop: "7.5%", display: "block"}} align="right">
           <font size="7" color="#8B0000">{stopDateDay}</font>&nbsp;
           <font size="7" color="#003e4d">{stopMonth}&nbsp;{stopYear}</font><br></br><br></br>
         </td>
     </tr>
   </table>
   <table align="center" width="80%">
    <tr>
      <td colspan="3"><br></br><br></br></td>
    </tr>
    <tr>
      <td style={{cursor:"pointer"}} align="center" class="box1003" width="100%" onClick={() => { history.push("/transfer/" + h); }} title={textObj && textObj[lang]["Components"]["Buttons"]["Transfer"]["title"]}>
        <a title={textObj && textObj[lang]["Components"]["Buttons"]["Transfer"]["title"]}><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["Transfer"]["name"]}</font> </a>
      </td>
      <td width="20%"></td>
    </tr>
      <tr>
         <td colspan="3"><br></br><br></br></td>
      </tr>
      <tr>
         <td
            align="center"
            class="box1003"
            width="100%"
            style={{cursor:"pointer"}}
            onClick={() => { window.open("https://etcblockexplorer.com/address/" + h, "_blank"); }}
            title={textObj && textObj[lang]["Components"]["Buttons"]["History"]["title"]}
         >
            <a title={textObj && textObj[lang]["Components"]["Buttons"]["History"]["title"]}>
              <font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["History"]["name"]}</font>
            </a>
        </td><td width="20%"></td>
      </tr>
      <tr>
         <td colspan="3"><br></br><br></br></td>
      </tr>
    </table>
    <table align="center" width="80%">
       <tr>
          <td colspan="3"><br></br><br></br></td>
       </tr>
       <tr>
          <td style={{cursor:"pointer"}} id="pub" align="left">
             <a onClick={(e) => showPublicKey(e)} title={textObj && textObj[lang]["Components"]["FieldTitles"]["PubKey"]}><font size="7" color="#8B0000">{textObj && textObj[lang]["Components"]["FieldTitles"]["PubKey"]}</font> </a>
          </td>
          <td width="100%"></td>
          <td><br></br><br></br></td>
       </tr>
       <tr>
          <td colspan="3"><br></br><br></br></td>
       </tr>
       <tr>
          <CopyToClipboard text={addr && addr[0].address}>
            <td onClick={() => setSnackbar({ isVisible: true, text: textObj[lang]["Components"]["Messages"]["KeyCopied"], color: "#2b2b2b" })} style={{cursor:"pointer"}} align="center" class="box1003" width="100%"><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["Copy"]["name"]}</font></td>
          </CopyToClipboard>
          <td width="20%"></td>
          <td width="40%"></td>
       </tr>
       <tr>
          <td colspan="3"><br></br><br></br></td>
       </tr>
       <tr>
          <td onClick={(e) => showCreatMsg()}  style={{cursor:"pointer"}} id="priv" align="left">
             <a title={textObj && textObj[lang]["Components"]["FieldTitles"]["PrivKey"]}><font size="7" color="#8B0000">{textObj && textObj[lang]["Components"]["FieldTitles"]["PrivKey"]}</font> </a>
          </td>
          <td width="100%"></td>
          <td><br></br><br></br></td>
       </tr>
       <tr>
          <td colspan="3"><br></br><br></br></td>
       </tr>
       <tr>
          <CopyToClipboard text={privKey}>
             <td onClick={() => {
                   if (privKey)
                     setSnackbar({ isVisible: true, text: textObj[lang]["Components"]["Messages"]["KeyCopied"], color: "#2b2b2b" })
                   else
                     showCreatMsg()
                 }}  
                 style={{cursor:"pointer"}} align="center" class="box1003" width="100%"><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["Copy"]["name"]}</font></td>
          </CopyToClipboard>
          <td width="20%"></td>
          <td width="40%"></td>
       </tr>
    </table>
    <table align="center" width="80%">
       <tr>
          <td colspan="3"><br></br><br></br></td>
       </tr>
       <tr>
          <td onClick={(e) => showRefLink(e)} style={{cursor:"pointer", wordBreak: "break-all"}} id="refLink" align="left">
             <a title={textObj && textObj[lang]["Components"]["FieldTitles"]["RefLink"]}><font size="7" color="#8B0000">{textObj && textObj[lang]["Components"]["FieldTitles"]["RefLink"]}</font> </a>
          </td>
          <td width="100%"></td>
          <td><br></br><br></br></td>
       </tr>
       <tr>
          <td colspan="3"><br></br><br></br></td>
       </tr>
       <tr>
          <CopyToClipboard text={ROOT_PATH + "/" + refLink}>
             <td onClick={() => setSnackbar({ isVisible: true, text: textObj[lang]["Components"]["Messages"]["LinkCopied"], color: "#2b2b2b" })} style={{cursor:"pointer"}} align="center" class="box1003" width="100%"><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["Copy"]["name"]}</font></td>
          </CopyToClipboard>
          <td width="20%"></td>
          <td width="40%"></td>
       </tr>
       <tr>
          <td colspan="3"><br></br><br></br></td>
       </tr>
    </table>
    <table align="center" width="80%">
    <tr>
     <td align="left" style={{position: "absolute"}}><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Stat"]["Referals"]}</font></td>
     <td width="100%"></td>
     <td style={{marginTop: "16%", display: "block"}} align="right">
      <font size="7" color="#8B0000">{refCount}</font>&nbsp;<font size="7" color="#003e4d"></font><br></br><br></br>
     </td>
   </tr>   
   <tr>
     <td align="left" style={{position: "absolute"}}><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Stat"]["Bonus"]}</font></td>
     <td width="100%"></td>
     <td style={{marginTop: "16%", display: "block"}} align="right">
      <font size="7" color="#8B0000">{curBonus}</font>&nbsp;<font size="7" color="#003e4d">ТОК</font><br></br><br></br>
     </td>
   </tr>
 </table>
  <br></br><br></br> 
    <table align="center" width="80%">
       <tr>
          <td style={{cursor: "pointer"}} align="center" class="box1003" width="100%" onClick={() => { payBonus(); }} title={textObj && textObj[lang]["Components"]["Buttons"]["ReceiveBonus"]["title"]}>
             <a title={textObj && textObj[lang]["Components"]["Buttons"]["ReceiveBonus"]["title"]}><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["ReceiveBonus"]["name"]}</font> </a>
          </td>
          <td width="20%"></td>
      </tr>
      <tr>
         <td colspan="3"><br></br><br></br></td>
      </tr>
    </table>
    <table align="center" width="80%">
       <tr>
          <td colspan="3"><br></br><br></br></td>
       </tr>
    </table>
    <SnackBar
      text={snackbar.text}
      lang={lang}
      textObj={textObj}
      color={snackbar.color}
      isVisible={snackbar.isVisible}
      timeOut={2000}
      setVisible={v => setSnackbar({ ...snackbar, isVisible: v })}
    />
   <BottomMenu lang={lang} textObj={textObj}/>
  <LoginTimeout/>
 </>
 )
}