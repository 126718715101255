import React from 'react';
import { useHistory } from "react-router-dom";

export default function (props) {
  let history  = useHistory();

  return (
    <div
      className={(props.isVisible ? "snackbar-visible" : "snackbar")}
      onClick={() => { props.setVisible(false); if (props.redir) history.push(props.redir); window.location.reload();}}
      style={{width: "50%", maxWidth: "50%",  maxHeight: "350px", margin: "0px auto"}}>
     <br></br>
      <div style={{minHeight: "55%", wordWrap: "break-word"}}>{props.text}</div>
    </div>
  )
}
