import React from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { TopMenu, BottomMenu, SnackBar, LoginTimeout } from "../../components";
import { ROOT_PATH, API_PATH, MESSAGES} from '../../constants';
import { syncTexts } from "../../util"

export default function (props) {
  let history = useHistory();
  let token   = localStorage.getItem("token");
  if (!token) history.push("/");
  let user = JSON.parse(localStorage.user)
  let lang = JSON.parse(localStorage.getItem("lang")) || "en";

  let [snackbar, setSnackbar] = React.useState({ isVisible: false, text: "", color: "" });
  let [snackbar2, setSnackbar2] = React.useState({ isVisible: false, text: "", color: "" });
  let [passwordFieldsText, setPasswordFieldsText] = React.useState("");
  let [oldPasswordFieldsText, setOldPasswordFieldsText] = React.useState("");
  let [confirmPasswordFieldsText, setConfirmPasswordFieldsText] = React.useState("");
  let [textObj, setTextObj] = React.useState("")

  React.useEffect(async () => {
    if (!textObj) {
      setTextObj(JSON.parse(localStorage.getItem("texts")))
      let t = await syncTexts(); 
      setTextObj(t);
    }
  })

  // Проверка пароля (от 10 до 30 символов)

  let checkPassword = (value) => {
     if (value.length >= 10 && value.length <= 30) return true;
     return false;
  }

  // Смена пароля

  let onChangePassword = () => {
    if (!checkPassword(oldPasswordFieldsText)) {
      setSnackbar({
        text: textObj && textObj[lang]["Components"]["Messages"]["changePasswordRules"],
        isVisible: true,
        color: "#2b2b2b",
      });
      return;
    }

    if(passwordFieldsText !== confirmPasswordFieldsText) {
       setSnackbar({
         text: textObj && textObj[lang]["Components"]["Messages"]["passwordDontMatch"],
         isVisible: true,
         color: "#2b2b2b",
       });
       return;
    }

    if (!checkPassword(passwordFieldsText)) {
       setSnackbar({
          text: textObj && textObj[lang]["Components"]["Messages"]["changePasswordRules"],
          isVisible: true,
          color: "#2b2b2b",
       });
      return;
    }

    axios
      .post(`${API_PATH}/user/password`, {
         token:       token,
         login:       user.login,
         password:    passwordFieldsText,
         oldPassword: oldPasswordFieldsText,
         lang:        lang })
      .then(({ data }) => {
          setSnackbar2({ text: data.message, color: "#2b2b2b", isVisible: true });
      })
      .catch((err, data) => {
         setSnackbar2({ isVisible: true, text: textObj[lang]["Components"]["Messages"]["serverError"], color: "#2b2b2b" });
      });
  }

return (
  <>
    <div style={{ minHeight: "100%" }}>
    <br></br><br></br>
    <TopMenu navLink   = "/settings"
             pageTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["ChangePassword"]["name"]}
             title     = {textObj && textObj[lang]["Components"]["TopMenu"]["ChangePassword"]["title"]}
             icon      = "<">
    </TopMenu>
      <table align="center" width="80%">
        <tr>
          <td colspan="3"><br></br><br></br><br></br></td>
        </tr>
        <tr>
          <td align="left">
             <span class="nowrap"><font size="7" color="#8B0000">{textObj && textObj[lang]["Components"]["FieldTitles"]["SetOldPass"]}</font></span>
          </td>
          <td width="100%">
          </td>
          <td><br></br></td>
        </tr>
        <br></br>
        <tr>
          <td width="60%">
            <font size="7" color="#003e4d">
             <form autocomplete="off">
              <input type="text" autocomplete="nope"
                 style={{ width: "136%", fontSize: "42px", color: "#003e4d",
                  border: "4px solid", paddingTop: "4%",   paddingBottom: "4%",
                  paddingLeft: "1%"}}
                 value={oldPasswordFieldsText}
                 onChange={(e) => setOldPasswordFieldsText(e.target.value)}
            />
            </form>
           </font>
          </td>
          <td width="20%"></td>
          <td width="20%"></td>
        </tr>
        <br></br>
        <tr>
          <td align="left">
             <span class="nowrap"><font size="7" color="#8B0000">{textObj && textObj[lang]["Components"]["FieldTitles"]["SetNewPass"]}</font></span>
          </td>
          <td width="100%">
          </td>
          <td><br></br></td>
        </tr>
        <br></br>
        <tr>
          <td width="60%">
            <font size="7" color="#003e4d">
             <form autocomplete="off">
              <input type="text" autocomplete="nope"
                 style={{ width: "136%", fontSize: "42px", color: "#003e4d",
                  border: "4px solid", paddingTop: "4%",   paddingBottom: "4%",
                  paddingLeft: "1%"}}
                 value={passwordFieldsText}
                 onChange={(e) => setPasswordFieldsText(e.target.value)}
            />
             </form>
           </font>
          </td>
          <td width="20%"></td>
          <td width="20%"></td>
        </tr>
        <tr>
          <td colspan="3"><br></br></td>
        </tr>
        <tr>
          <td align="left">
            <span class="nowrap"><font size="7" color="#8B0000">{textObj && textObj[lang]["Components"]["FieldTitles"]["RepeatNewPass"]}</font></span>
          </td>
          <td width="100%"></td>
          <td><br></br></td>
        </tr>
        <tr>
          <td align="left"></td>
          <td width="100%"></td>
          <td><br></br></td>
        </tr>
        <tr>
          <td>
            <font size="7" color="#003e4d">
             <form autocomplete="off">
              <input type="text" autocomplete="nope"
                style={{ width: "136%", fontSize: "42px", color: "#003e4d",
                  border: "4px solid", paddingTop: "4%",   paddingBottom: "4%",
                  paddingLeft: "1%"}}
                value={confirmPasswordFieldsText}
                onChange={(e) => setConfirmPasswordFieldsText(e.target.value)}
             />
             </form>
           </font>
         </td>
         <td width="20%"></td><td width="20%"></td>
        </tr>
        <tr>
          <td colspan="3"><br></br></td>
        </tr>
     </table>
        <SnackBar
           text={snackbar.text}
           lang={lang} 
           textObj={textObj}
           isVisible={snackbar.isVisible}
           style={{marginLeft: "5%"}}
           setVisible={v => setSnackbar({ ...snackbar, isVisible: v })}
        />
        <SnackBar
           text={snackbar2.text}
           lang={lang} 
           textObj={textObj}
           isVisible={snackbar2.isVisible}
           style={{marginLeft: "5%"}}
           redir={"/settings"}
           setVisible={v => setSnackbar({ ...snackbar2, isVisible: v })}
        />
     <table align="center" width="80%">
        <tr>
          <td width="100%">
            <tr>
              <td colspan="3"><br></br></td>
            </tr>
            <tr>
              <td align="center"
                  class="box1003"
                  width="1%"
                  title={textObj && textObj[lang]["Components"]["Buttons"]["Save"]["title"]}
                  style={{cursor: "pointer"}}
                  onClick={() => onChangePassword()}
              >
                <a title={textObj && textObj[lang]["Components"]["Buttons"]["Save"]["title"]}>
                  <font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["Save"]["name"]}</font>
                </a>
              </td>
            </tr>
          </td>
        </tr>
        <br></br>
        <tr>
          <td width="100%">
            <tr>
               <td colspan="3"><br></br></td>
            </tr>
            <tr>
              <td align="center"
                  class="box1003"
                  width="1%"
                  style={{cursor: "pointer"}}
                  onClick={() => { history.push("/settings"); }}
                  title={textObj && textObj[lang]["Components"]["Buttons"]["Cancel"]["title"]}
              >
                  <a title={textObj && textObj[lang]["Components"]["Buttons"]["Cancel"]["title"]}>
                    <font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["Cancel"]["name"]}</font>
                  </a>
              </td>
            </tr><br></br><br></br><br></br>
          </td>
        </tr>
    </table>
    <br></br>
    </div>
  <BottomMenu lang={lang} textObj={textObj}/>
 <LoginTimeout/>
</>
 )
}
