import React, { Component }  from "react";
import axios from 'axios';
import { CryptBaseCur, Months, API_PATH } from './constants';

/**
 * Проверка пароля. Возвращает true если
 * строка >= 10 и <= 30 символам, иначе false
 * @param value: String -> строка для проверки
 * @returns: Boolean
 */

export const checkPassword = (value) => {
   if (value.length >= 10 && value.length <= 30) return true
   return false
}

/**
 * Принимает UNIX timestamp и возвращает
 * массив вида [год,месяц,день...]
 * @param timestamp: String -> UNIX timestamp
 * @returns: Array
 */

export const timeConverter = (timestamp) => {
   let a = new Date(timestamp * 1000)
   let year = a.getFullYear()
   let month = Months[a.getMonth()]
   let date = a.getDate()
   let hour = a.getHours()
   let min = a.getMinutes()
   let sec = a.getSeconds()
   return [formatNumberLength(year)
          ,formatNumberLength(month)
          ,formatNumberLength(date)
          ,formatNumberLength(hour)
          ,formatNumberLength(min)
          ,formatNumberLength(sec)]
}

/**
 * Форматирование даты и времени
 */

export const formatNumberLength = (num, length) => {
  let r = "" + num
  while (r.length < length) r = "0" + r
  return r
}

/**
 * Получение token rate из смарт контракта
 * через API
 */

export const getTokenRate = () => {
  axios
   .post(`${API_PATH}/token/getTokenRate`)
   .then(({ data }) => {
      if (data.status == "success")
         return data.tokenRate
      else {
         return data.message
      }
   })
   .catch((err) => {
      return err
   });
}

/**
 * Синхронизация текстов с сервером 
 */

export const syncTexts = () => {
  return axios
   .post(`${API_PATH}/syncTexts`, { thash: JSON.parse(localStorage.getItem("textsHash")) })
   .then(({ data }) => {
      if (data.message === "false") {
      	localStorage.setItem("texts", JSON.stringify(data.t))
        localStorage.setItem("textsHash", JSON.stringify(data.thash))
        return data.t
      } else {
        return JSON.parse(localStorage.getItem("texts"))
      }
   })
   .catch((err) => {
      return err
   });
}

/**
 * Возвращает true, если строка является валидным
 * ethereum адресом, иначе false
 */

export const isAddress = (address) => {
  if (! (/^(0x)?[0-9a-fA-F]{40}$/i.test(address)))
     return false

  return true
}

/**
 * Возвращает true, если строка является валидным
 * приватным ключем ethereum, иначе false
 */

export const isPrivKey = (pKey) => {
  if (! (/^(0x)?[0-9a-fA-F]{64,}$/i.test(pKey)))
     return false

  return true
}

export const updateRUBPrice = async () => {
  try {
    let price = 0
    return axios
        .get(`https://rest.coinapi.io/v1/exchangerate/ETC/RUB?apikey=A713FFA7-D289-4B83-936C-15EA30DDD915`)
        .then(({ data }) => {
            if (data.rate > 1)
                price = Math.round(data.rate * 100) / 100
            else
                price = data.rate.toFixed(2)
       
            return price
        }) 
    } catch (e) {
        return 0
    }
    
}

export const getAllCurrencies = async () => {
  try {
    return axios
        .post(`${API_PATH}/user/getAllCurrencies`, {})
        .then(({ data }) => {
            if (data.status == "success") {
                let rub = {"count":"1","price":"1","name":"RUR"}
                data.currencies.push(rub)
                localStorage.setItem("currList", JSON.stringify(data.currencies))
                return data.currencies
            }
        })
        .catch((err) => {
            console.log(err)
        });
   } catch (e) {
       return new Object();
   }
}

export const updateFiatPrice = async (curList, fiatName) => {
  try {
    let count, price, name
    curList && curList.map((object, i) => {
        if (object["name"] === fiatName) {
            name  = object["name"] 
            price = object["price"]  
            count = object["count"]  
            return  
        }   
    })
    return Number.parseFloat(price) / count
  } catch (e) {
      return 0
  }
}

export const tokenToEth = (amount, tokenRate) => {
   return tokenRate * amount
}

export const ethToToken = (amount, tokenRate) => {
   return Math.floor(amount / tokenRate)
}

export const ethFromFiat = (amount, type, price1, price2, currency1, currency2) =>
{
  if (type == currency1)
    return amount / price1.price
  else if (type == currency2)
    return amount / price2.price
}

export const ethToFiat = (amount, type, price1, price2, currency1, currency2) =>
{
  console.log(amount, type, price1, price2, currency1, currency2)
  if (type == currency1)
    return price1.price * amount
  else if (type == currency2)
    return price2.price * amount
}
