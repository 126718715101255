import React from 'react';
import { useHistory } from "react-router-dom";
import { ROOT_PATH, MESSAGES } from '../../constants';
import { SnackBar } from "../../components";

export default function (props) {
  let history = useHistory();
  let lang    = props.lang || "en";
  const [snackbar, setSnackbar] = React.useState({ isVisible: false, text: "", color: "", token: ""});

  let handleClick = (v) => {
     if (!props.token && !localStorage.token)  {
       setSnackbar({
          text:      props.textObj[lang]["Components"]["Messages"]["needAuth"],
          isVisible: true,
          color:     "#2b2b2b",
       });
     }
     else {
       history.push(v);
       if (v == "/contract")
          window.location.reload();
     }
  }

  // Выход из приложения

  let logOut = () => {
      localStorage.token = '';
      history.push("/");
  }

  return (
    <>
      <table style={{position: "fixed", bottom: "0", background: "#ffffff", left: "0px"}} align="center" width="100%">
      <tr>
        <td colspan="4"><hr class="line" color="#003e4d" size="5px" width="90%"/><br></br></td>
      </tr>
      <tr>
        <td align="center" width="25%">
          <a onClick={() => { handleClick("/address/all"); }} style={{cursor: "pointer"}} title={props.textObj && props.textObj[lang]["Components"]["BottomMenu"]["Addresses"]}>
            <img src={`${ROOT_PATH}/izo/a.png`} border="0"/> </a><br></br><br></br>
        </td>
        <td align="center" width="25%">
          <a onClick={() => { handleClick("/contract"); }} style={{cursor: "pointer"}} title={props.textObj && props.textObj[lang]["Components"]["BottomMenu"]["Contract"]}>
            <img src={`${ROOT_PATH}/izo/k.png`} border="0"/> </a><br></br><br></br>
        </td>
        <td align="center" width="25%">
          <a  onClick={() => { handleClick("/settings");}} style={{cursor: "pointer"}} title={props.textObj && props.textObj[lang]["Components"]["BottomMenu"]["Settings"]}>
            <img src={`${ROOT_PATH}/izo/n.png`} border="0"/> </a><br></br><br></br>
        </td>
        <td align="center" width="25%">
           <a onClick={() => { logOut(); }} style={{ cursor: "pointer" }} title={props.textObj && props.textObj[lang]["Components"]["BottomMenu"]["Exit"]}>
              <img src={`${ROOT_PATH}/izo/s.png`} border="0"/>
           </a><br></br><br></br>
        </td>
      </tr>
    </table>
    <br></br>
    <SnackBar
      text={snackbar.text}
      lang={lang}
      textObj={props.textObj}
      isVisible={snackbar.isVisible}
      timeOut={2000}
      setVisible={v => setSnackbar({ ...snackbar, isVisible: v })}
    />
    <br></br><br></br><br></br><br></br><br/>
    </>
  )
}
