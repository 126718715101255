import React from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { TopMenu, BottomMenu, SnackBar } from "../../components";
import { ROOT_PATH, API_PATH, MESSAGES, VALIDATED_FIELDS } from '../../constants';
import { syncTexts } from "../../util"

export default function (props) {  
   let history = useHistory();
   let lang = JSON.parse(localStorage.getItem("lang")) || "en";

   let [snackbar, setSnackbar] = React.useState({ isVisible: false, text: "", color: "" });
   let [loginFieldsText, setLoginFieldsText] = React.useState("");
   let [passwordFieldsText, setPasswordFieldsText] = React.useState("");
   let [confirmPasswordFieldsText, setConfirmPasswordFieldsText] = React.useState("");
   let [knowRules, setKnowRules] = React.useState(false);
   let [textObj, setTextObj] = React.useState("")

   React.useEffect(async () => {
      if (!textObj) {
        setTextObj(JSON.parse(localStorage.getItem("texts")))
        let t = await syncTexts(); 
        setTextObj(t);
      }
   })

   function register() {
      if (!loginFieldsText) {
        setSnackbar({
           text: textObj[lang]["Components"]["Messages"]["registerRules"],
           isVisible: true,
           color: "#2b2b2b",
        });
        return;
      }

      if (!knowRules) {
        document.getElementById("top_message").style.display="block";
        return;
      }

      if (loginFieldsText.length < 10 || loginFieldsText.length > 30) {
        setSnackbar({
             text: textObj[lang]["Components"]["Messages"]["registerRules"],
             isVisible: true,
             color: "#2b2b2b",
        });
        return;
      }

      if (!checkPassword(passwordFieldsText)) {
         setSnackbar({
            text: textObj[lang]["Components"]["Messages"]["registerRules"],
            isVisible: true,
            color: "#2b2b2b",
         });
         return;
      }

      if(passwordFieldsText !== confirmPasswordFieldsText) {
         setSnackbar({
              text: textObj[lang]["Components"]["Messages"]["passwordDontMatch"],
              isVisible: true,
              color: "#2b2b2b",
         });
         return;
      }

      let referrer = localStorage.getItem("referrer");

      axios
         .post(`${API_PATH}/register`,
           {
             login:    loginFieldsText,
             password: passwordFieldsText,
             referrer: referrer || "N/A",
             lang: lang
           }
         )
         .then(({ data }) => {
             console.log(data);
              if (data.status == "success") {
               setSnackbar({ text: data.message, isVisible: true, color: "#2b2b2b" });
               history.push("/login");
             } else {
                setSnackbar({ isVisible: true, text: data.message, color: "#2b2b2b" });
             }
         })
         .catch((err, data) => {
           setSnackbar({ isVisible: true, text: textObj[lang]["Components"]["Messages"]["serverError"], color: "#2b2b2b" });
           console.log(err)
         });
    }

    // Проверка пароля (от 10 до 30 символов)

    let checkPassword = (value) => {
       if (value.length >= 10 && value.length <= 30) return true;
       return false;
    }

    return (
     <>
        <br></br><br></br>
        <TopMenu
            navLink   = "/login"
            pageTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["Register"]["name"]}
            menuTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["Register"]["title"]}
            icon      = "<">
       </TopMenu>
       <div
         className="top_message"
         id="top_message"
         align="center"
         width="50%"
         style={{display: "none", maxHeight: "max-content"}}>
         <br></br>
           {textObj && textObj[lang]["PageText"]["RegisterPage"][0]}
           <br></br><br></br>
         <button
            onClick={(e) => { history.push("/manual/text"); document.getElementById("top_message").style.display="none" }}
            style={{cursor: "pointer", fontSize: "0.8em", paddingTop: "1.8%", paddingBottom: "1.8%",
              fontWeight: "500", background: "#c45331", color: "#fff", minWidth: "61%",
              paddingLeft: "10%", paddingRight: "10%", borderColor: "#fff",
              border: "3px solid"}}
            align="center"
            className="box1003"
            title= {textObj && textObj[lang]["PageText"]["RegisterPage"][1]}>
            {textObj && textObj[lang]["PageText"]["RegisterPage"][1]}
         </button>
         <br></br> <br></br>
         <button
            onClick={(e) => { document.getElementById("top_message").style.display="none"}}
            style={{cursor: "pointer", fontSize: "0.8em", paddingTop: "1.8%", paddingBottom: "1.8%",
              fontWeight: "500", background: "#c45331", color: "#fff", minWidth: "55%",
              paddingLeft: "10%", paddingRight: "10%", borderColor: "#fff",
              border: "3px solid"}}
            align="center"
            className="box1003"
            title={textObj && textObj[lang]["PageText"]["RegisterPage"][2]}>
           {textObj && textObj[lang]["PageText"]["RegisterPage"][2]}
         </button>
       </div>
        <table align="center" width="80%">
          <tbody>
           <tr>
            <td align="center">
               <form autocomplete="off">
                 <br></br><br></br><br></br>
                 <input
                    style={{width: "101%", fontSize: "42px", paddingTop: "1.8%", paddingBottom: "1.8%", paddingLeft: "1%"}}
                    className="box1003"
                    type="text"
                    maxLength="30"
                    placeholder={textObj && textObj[lang] && textObj[lang]["Components"]["Placeholders"]["Login"]["name"]}
                    title={textObj && textObj[lang] &&  textObj[lang]["Components"]["Placeholders"]["Login"]["title"]}
                    autocomplete="nope"
                    value={loginFieldsText}
                    onChange={(e) => setLoginFieldsText(e.target.value)}
                 />
                 <br></br><br></br><br></br>
                 <input
                    style={{width: "101%", fontSize: "42px", paddingTop: "1.8%", paddingBottom: "1.8%", paddingLeft: "1%"}}
                    className="box1003"
                    type="text"
                    placeholder={textObj && textObj[lang] && textObj[lang] && textObj[lang]["Components"]["Placeholders"]["Password"]["name"]}
                    title={textObj && textObj[lang] &&  textObj[lang]["Components"]["Placeholders"]["Password"]["title"]}
                    maxLength="30"
                    autocomplete="nope"
                    value={passwordFieldsText}
                    onChange={(e) => setPasswordFieldsText(e.target.value)}
                 />
                 <br></br><br></br><br></br>
                 <input
                    type="text"
                    style={{width: "101%", fontSize: "42px", paddingTop: "1.8%", paddingBottom: "1.8%", paddingLeft: "1%"}}
                    className="box1003"
                    placeholder={textObj && textObj[lang] &&  textObj[lang]["Components"]["Placeholders"]["ConfirmPassword"]["name"]}
                    maxLength="30"
                    title={textObj && textObj[lang] && textObj[lang]["Components"]["Placeholders"]["ConfirmPassword"]["title"]}
                    autocomplete="nope"
                    value={confirmPasswordFieldsText}
                    onChange={(e) => setConfirmPasswordFieldsText(e.target.value)}
                 />
                 <br></br><br></br>
                 <p style={{float: "right", position: "relative"}}>
                  <font size="6" color="#003e4d">{textObj && textObj[lang] && textObj[lang]["Components"]["CheckBoxes"]["ReadRules"]} &nbsp;&nbsp;</font>
                  <input
                    type="checkbox"
                    placeholder={textObj && textObj[lang] && textObj[lang]["Components"]["CheckBoxes"]["ReadRules"]}
                    title={textObj && textObj[lang] && textObj[lang]["Components"]["CheckBoxes"]["ReadRules"]}
                    id="password"
                    onChange={(e) => setKnowRules(!knowRules)}
                    style={{border: "2px solid", transform: "scale(2.0, 2.0)", top: "34%",
                      right: "-1%", position: "absolute"}}
                 />
                 </p>
                 <br></br><br></br><br></br><br></br>
                 <button
                    style={{cursor: "pointer", fontSize: "42px", paddingTop: "1.8%", paddingBottom: "1.8%",
                      fontWeight: "500", background: "#fff", color: "#003e4d", width: "101%"}}
                    align="center"
                    className="box1003"
                    onClick={(e) => { e.preventDefault(); register(); }}
                    title={textObj && textObj[lang]["Components"]["Buttons"]["Register"]["title"]}>
                  {lang === "en" ?
                     textObj && textObj[lang]["Components"]["Buttons"]["Register2"]["name"]
                      : textObj && textObj[lang]["Components"]["Buttons"]["Register"]["name"]}
                 </button>
                 <br></br><br></br><br></br>
                 <button
                    style={{cursor: "pointer", fontSize: "42px", paddingTop: "1.8%", paddingBottom: "1.8%",
                      fontWeight: "500", background: "#fff", color: "#003e4d", width: "101%"}}
                    align="center"
                    className="box1003"
                    onClick={(e) => { e.preventDefault(); history.push("/login"); }}
                    title={textObj && textObj[lang]["Components"]["Buttons"]["Login"]["title"]}>
                  {textObj && textObj[lang]["Components"]["Buttons"]["Login"]["name"]}
                 </button>
               </form>
               <br></br>
               <SnackBar
                 isVisible={snackbar.isVisible}
                 lang={lang}
                 textObj={textObj}
                 style={{marginLeft: "5%"}}
                 setVisible={v => setSnackbar({ ...snackbar, isVisible: v })}
                 text={snackbar.text}
                 color={snackbar.color}
                 timeOut={2000}
               />
            </td>
           </tr>
         </tbody>
        </table>
        <table align="left" width="100%" style={{marginLeft: "10%", width: "80%"}}>
          <tr align="left">
            <td width="80%">
             <br></br><br></br><font size="6" color="#8B0000">{textObj && textObj[lang]["PageText"]["RegisterPage"][3]} </font><font color="#003e4d" size="6">
            {textObj && textObj[lang]["PageText"]["RegisterPage"][4]}
             </font>
             <br></br><br></br><font size="6" color="#8B0000">{textObj && textObj[lang]["PageText"]["RegisterPage"][5]}  </font><font color="#003e4d" size="6">
               {textObj && textObj[lang]["PageText"]["RegisterPage"][6]}
              </font>
             </td>
          </tr>
         <br></br><br></br><br></br>
        </table>
        <br></br><br></br><br></br><br></br><br></br>
        <table align="center" width="80%">
          <tbody>
           <tr>
            <td align="center">
              <button
                style={{cursor: "pointer", fontSize: "42px", paddingTop: "1.8%", paddingBottom: "1.8%",
                  fontWeight: "500", background: "#fff", color: "#003e4d", width: "101%"}}
                align="center"
                className="box1003"
                onClick={(e) => { e.preventDefault(); history.push("/"); }}
                title={textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["title"]}>
                  {textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["name"]}
              </button>
            </td>
          </tr>
        </tbody>
       </table>
       <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
      </>
     )
    }
