import React from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { API_PATH } from '../../constants';

export default function (props) {
  let history = useHistory();

  React.useEffect(() => {
    if (!props.token && props.token === "" && !localStorage.token && localStorage.token === "") {
      history.push("/");
    }
    let token = (props.token === "" ? localStorage.token : props.token);
    axios.post(`${API_PATH}/checkToken`, { token: token }).then(({ data }) => {
      localStorage.setItem("token", token);
      props.setToken(token);
      if (data.status !== "success") {
        history.push("/");
      }
    }).catch(err => {
      console.log(err);
      history.push("/");
    });
  }, []);

  return <React.Fragment>{props.children}</React.Fragment>;
}
