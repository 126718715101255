import React from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { TopMenu, BottomMenu, LoginTimeout} from "../../components";
import { ROOT_PATH, API_PATH, CryptBaseCur } from '../../constants';
import { updateFiatPrice, updateRUBPrice, getAllCurrencies } from "../../util.js";
import { syncTexts } from "../../util"

export default function (props) {
  let history = useHistory();
  let token   = localStorage.getItem("token");
  if (!token) history.push("/");

  let lang      = JSON.parse(localStorage.getItem("lang")) || "en";
  let curr      = JSON.parse(localStorage.getItem("mainCurrency"));
  let usr       = JSON.parse(localStorage.getItem("user")); 
  let currList  = JSON.parse(localStorage.getItem("currList"));
  let currency1 = curr ? curr.mainCurrency : "USD";

  let [textObj, setTextObj] = React.useState("")
  let [contractObj, setContractObj] = React.useState({
     tokenBalance:  0,
     ethBalance:    0,
     stopSum:       0,
     depositsCount: 0,
  });
  
  let contract  = JSON.parse(localStorage.getItem("contract")) || contractObj;
  let [contractTokenBalance, setContractTokenBalance] = React.useState(contract.tokenBalance || 0);
  let [contractEthBalance, setContractEthBalance] = React.useState(contract.ethbalance || 0);
  let [stopSum, setStopSum] = React.useState(contract.stopSum >= 0 ? contract.stopSum : 0);
  let [share, setShare] = React.useState(contract.share || 0);
  let [depositsCount, setDepositsCount] = React.useState(contract.depositsCount || 0);
  let [fiat, setFiat] = React.useState({ price: 0, name : "..."});
  let [rubPrice, setRUBPrice] = React.useState(0);

  React.useEffect(async () => {
    if (!textObj) {
      setTextObj(JSON.parse(localStorage.getItem("texts")))
      let t = await syncTexts(); 
      setTextObj(t);
    }
  })

  let updateContract = () => {
    axios
       .post(`${API_PATH}/contract/getAll`, { login: usr.login, token: token })
       .then(({ data }) => {
          if (data.status == "success") {
             localStorage.setItem("contract", JSON.stringify(data.contract));
             setContractTokenBalance(data.contract.tokenBalance);
             setContractEthBalance(data.contract.ethbalance);
             setStopSum(data.contract.stopSum);
             setShare(data.contract.share);
             setDepositsCount(data.contract.depositsCount);
          }
       })
       .catch((err, data) => {
          console.log(err)
       });
  }

  window.onload = async () => {
    syncTexts()
    updateContract()
    if (!currList) currList = await getAllCurrencies()
    let res = await updateRUBPrice()
    let fPrice = await updateFiatPrice(currList, currency1)
    setFiat({ price: res / fPrice, name: currency1 })
    setRUBPrice(res)
  }

 return (
  <>
    <br></br><br></br>
    <TopMenu
        navLink   = "/"
        pageTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["Contract"]["name"]}
        menuTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["Contract"]["title"]}
        icon      = "X">
   </TopMenu>
   <div style={{ minHeight: "100%" }}>
   <table align="center" width="80%">
   <tr>
     <td colspan="3"><br></br><br></br><br></br></td>
   </tr>
   <tr>
     <td align="left"><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Stat"]["Tokens"]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</font></td>
     <td width="100%"></td>
     <td style={{marginTop: "3%", display: "block"}} align="right">
      <font size="7" color="#8B0000">{contractTokenBalance}</font>&nbsp;<font size="7" color="#003e4d">ТОК</font><br></br>
     </td>
   </tr>
   <tr>
    <td align="left"><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Stat"]["Ethereum"]}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</font></td>
    <td width="100%"></td>
    <td align="right" style={{marginTop: "9%", display: "block"}}>
      <font size="7" color="#8B0000">{parseFloat(contractEthBalance).toFixed(4)}</font>&nbsp;<font size="7" color="#003e4d">ЕТC</font><br></br><br></br>
    </td>
   </tr>
   <tr>
    <td align="left"><font size="7" color="#003e4d"></font></td><td width="100%"></td>
    <td style={{marginTop: "3%", display: "block"}} align="right">
      <font size="7" color="#8B0000">{contractEthBalance == 0 ? 0 : (fiat.price * contractEthBalance).toFixed(2)}</font>&nbsp;<font size="7" color="#003e4d">{currency1}</font><br></br><br></br>
    </td>
  </tr>
  <tr>
    <td colspan="3"><br></br><br></br><br></br></td>
  </tr>
  <tr>
    <td align="left"><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Stat"]["StopSum"]}</font></td>
    <td width="100%"></td>
    <td style={{marginTop: "9%", display: "block"}} align="right">
      <font size="7" color="#8B0000">{parseFloat(stopSum).toFixed(4)}</font>&nbsp;<font size="7" color="#003e4d">ЕТC</font><br></br><br></br>
    </td>
  </tr>
  <tr>
    <td align="left"><font size="7" color="#003e4d"></font></td>
    <td width="100%"></td>
    <td style={{marginTop: "3%", display: "block"}} align="right">
      <font size="7" color="#8B0000">{stopSum == 0 ? 0 : (fiat.price * stopSum).toFixed(2)}</font>&nbsp;<font size="7" color="#003e4d">{currency1}</font><br></br><br></br>
    </td>
  </tr>
  <tr>
    <td colspan="3"><br></br><br></br><br></br></td>
  </tr>
  <tr>
    <td align="left"><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Stat"]["Share"]}</font></td>
    <td width="100%"></td>
    <td style={{marginTop: "9%", display: "block"}} align="right">
      <font size="7" color="#8B0000">{share && parseFloat(share).toFixed(4) || parseFloat(0).toFixed(4)}</font>&nbsp;<font size="7" color="#003e4d">ЕТC</font><br></br><br></br>
    </td>
  </tr>
  <tr>
    <td align="left"><font size="7" color="#003e4d"></font></td>
    <td width="100%"></td>
    <td style={{marginTop: "3%", display: "block"}} align="right">
      <font size="7" color="#8B0000">{share == 0 ? 0 : parseFloat(fiat.price * share).toFixed(2)}</font>&nbsp;<font size="7" color="#003e4d">{currency1}</font><br></br><br></br>
    </td>
  </tr>
 <tr>
   <td colspan="3"><br></br><br></br><br></br></td>
 </tr>
 <tr>
   <td align="left"><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Stat"]["Deposits"]}</font></td>
   <td width="100%"></td>
   <td style={{marginTop: "9%", display: "block"}} align="right">
    <font size="7" color="#8B0000">{depositsCount}</font><br></br><br></br>
   </td>
 </tr>
</table>
<table align="center" width="80%">
 <tr>
   <td colspan="3"><br></br><br></br><br></br><br></br></td>
 </tr>
 <tr>
    <td align="center" class="box1003" style={{cursor:"pointer"}} width="100%" onClick={() => { history.push("/"); }} title={textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["title"]}>
      <a onClick={() => { history.push("/"); }} title={textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["title"]}><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["name"]}</font> </a>
    </td>
 </tr>
 <br></br><br></br>
  <tr>
    <td align="center" class="box1003" style={{cursor:"pointer"}} width="100%" onClick={() => { history.push("/"); }} title="YouTube">
      <a onClick={() => { window.location.replace("https://www.youtube.com/results?search_query=QUADRATE+Ecosystem"); }} title="YouTube"><font size="7" color="#003e4d">YouTube</font> </a>
    </td>
 </tr>
 <tr>
    <td colspan="3"><br></br><br></br><br></br><br></br></td>
 </tr>
</table>
<BottomMenu lang={lang} textObj={textObj}/>
</div>
<LoginTimeout/>
</>)
}
