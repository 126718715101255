import React, { Component }  from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { Months, CryptBaseCur, ROOT_PATH, API_PATH } from '../../constants';
import { updateFiatPrice
        ,updateRUBPrice
        ,formatNumberLength
        ,ethToToken
        ,tokenToEth
        ,ethToFiat
        ,ethFromFiat
        ,getAllCurrencies } from "../../util.js";

export default function (props) {
  let history =  useHistory();
  let lang  =  props.lang || "en";
  let curr, curr2, currList
  
  try {
    curr     =  JSON.parse(localStorage.getItem("currencies"));
    curr2    =  JSON.parse(localStorage.getItem("currencies2"));
    currList =  JSON.parse(localStorage.getItem("currList"));
  } catch (e) {}
  
  let currency1         =  curr && curr.currency1 || "USD";
  let currency2         =  curr && curr.currency2 || "EUR";
  let currency3         =  curr && curr.currency3 || "RUR";
  let currency4         =  curr2 && curr2.currency4 || "USD";
  let currency5         =  curr2 && curr2.currency5 || "EUR";
  let crypt_target_cur  =  new Array(currency1, currency2, currency3);
  let crypt_target_cur2 =  new Array(currency4, currency5);
  let tokenRate         =  0.005;

  let [ts, setTS]         =  React.useState(0);
  let [price1, setPrice1] =  React.useState({ name: "...", price: 0 });
  let [price2, setPrice2] =  React.useState({ name: "...", price: 0 });
  let [price3, setPrice3] =  React.useState({ name: "...", price: 0 });
  let [price4, setPrice4] =  React.useState({ name: "...", price: 0 });
  let [price5, setPrice5] =  React.useState({ name: "...", price: 0 });

  let [tokenAmount, setTokenAmount] = React.useState("");
  let [ethAmount, setEthAmount]     = React.useState("");
  let [cur4Amount, setCur4Amount]   = React.useState("");
  let [cur5Amount, setCur5Amount]   = React.useState("");

  // Виджет1

  let getCurrencies = async (rub) => {
    for (let i = 0; i < crypt_target_cur.length; i++) {
      let fPrice = await updateFiatPrice(currList, crypt_target_cur[i])

      if (i === 0)
        setPrice1({ price: (rub / fPrice).toFixed(2), name: crypt_target_cur[i] })
      else if (i == 1)
        setPrice2({ price: (rub / fPrice).toFixed(2), name: crypt_target_cur[i] })
      else
        setPrice3({ price: (rub / fPrice).toFixed(2), name: crypt_target_cur[i] })      
    }

    setTS(Date.now())
  }

  let date = new Date(ts);
  let day = formatNumberLength(date.getDate(), 2);
  let month = Months[date.getMonth()];
  let year = date.getFullYear();
  let hour = formatNumberLength(date.getHours(), 2);
  let minutes = formatNumberLength(date.getMinutes(), 2);
  let seconds = formatNumberLength(date.getSeconds(), 2);
  let time_zone = "UTC+0:00";

  // Виджет 2
  
  let getCurrencies2 = async (rub) => {
    for (let i = 0; i < crypt_target_cur2.length; i++) {
      let fPrice = await updateFiatPrice(currList, crypt_target_cur2[i])

      if (i === 0)
        setPrice4({ price: (rub / fPrice).toFixed(2), name: crypt_target_cur2[i] })
      else
        setPrice5({ price: (rub / fPrice).toFixed(2), name: crypt_target_cur2[i] })
    }
  }

  let handleFormInput = (type, value) => {
    if (type == "ether") {
      if (/[^0-9\.]/.test(value))
        setEthAmount("")
      else {
        setEthAmount(value)
        setTokenAmount(ethToToken(value, tokenRate))
        setCur4Amount(ethToFiat(value, currency4, price4, price5, currency4, currency5))
        setCur5Amount(ethToFiat(value, currency5, price4, price5, currency4, currency5))
      }
    } else if (type == "token") {
      if (/[^0-9]/.test(value))
        setTokenAmount("")
      else {
        setTokenAmount(value)
        let ethval = tokenToEth(value, tokenRate)
        setEthAmount(ethval)
        setCur4Amount(ethToFiat(ethval, currency4, price4, price5, currency4, currency5))
        setCur5Amount(ethToFiat(ethval, currency5, price4, price5, currency4, currency5))
      }
    } else if (type == currency4) {
       if (/[^0-9\.]/.test(value))
           setCur4Amount("")
       else {
         let ethval = ethFromFiat(value, currency4, price4, price5, currency4, currency5)
         setCur4Amount(value)
         setEthAmount(ethval)
         setTokenAmount(ethToToken(ethval, tokenRate))
         setCur5Amount(ethToFiat(ethval, currency5, price4, price5, currency4, currency5))
       }
    } else if (type == currency5) {
       if (/[^0-9\.]/.test(value))
           setCur5Amount("")
       else {
         let ethval = ethFromFiat(value, currency5, price4, price5, currency4, currency5)
         setCur5Amount(value)
         setEthAmount(ethval)
         setTokenAmount(ethToToken(ethval, tokenRate))
         setCur4Amount(ethToFiat(ethval, currency4, price4, price5, currency4, currency5))
       }
    }
  }

  // Загрузка

  window.onload = async () => { 
    if (!currList) currList = await getAllCurrencies()
    let res = await updateRUBPrice()
    await getCurrencies(res)
    await getCurrencies2(res)
  }

  return (
    <>
    <tr align="left">
      <td colspan="3">
        <div id="currencies">         
          <div className="cryptonatorwidget" style={{ borderRadius: "4px" }}>
            <br></br><br></br>
            <div style={{ color: "#003e4d", padding: "10px"}}>
              <div>
                <font style={{ fontSize: "90%" }}> <font style={{ color: "#8B0000" }}>1</font>
                <font style={{ color: "#003e4d" }}>ETC = </font></font>
                <span>
                  <font style={{ color: "#8B0000" }}>{price1.price}</font>
                  <font style={{ color: "#003e4d" }}>  {currency1}</font>
                </span>
              </div>
              <br></br>
              <div>
                <font style={{ fontSize: "90%" }}> <font style={{ color: "#8B0000" }}>1</font>
                <font style={{ color: "#003e4d" }}>ETC = </font></font>
                <span>
                  <font style={{ color: "#8B0000" }}>{price2.price}</font>
                  <font style={{ color: "#003e4d" }}>  {currency2}</font>
                </span>
              </div>
              <br></br>
              <div>
                <font style={{ fontSize: "90%" }}> <font style={{ color: "#8B0000" }}>1</font>
                <font style={{ color: "#003e4d" }}>ETC = </font></font>
                <span>
                  <font style={{ color: "#8B0000" }}>{price3.price}</font>
                  <font style={{ color: "#003e4d" }}>  {currency3}</font>
                </span>
              </div>
            </div>

            <div style={{ fontSize: "70%", paddingTop: "20px", paddingLeft: "10px", color: "#003e4d" }}>
            <br></br>
            {
               day + '.' + month + '.' + year + ' '
            } 
            <br></br>
            {
               hour + ':' + minutes + ':' + seconds + ' '
            }
            </div>
            <br></br>
         </div>
        </div>
       </td>
      </tr>
      <tr>
          <td colspan="3"><br></br></td>
      </tr>
      <tr>
         <td align="center" class="box1003" style={{cursor:"pointer"}} width="100%" onClick={() => { history.push("/currency/board"); window.location.reload(); }} title={props.textObj && props.textObj[lang]["Components"]["Buttons"]["ChangeCurrency"]["title"]}>
            <a onClick={() => { history.push("/currency/board"); }} title={props.textObj && props.textObj[lang]["Components"]["Buttons"]["ChangeCurrency"]["title"]}><font size="7" color="#003e4d">{props.textObj && props.textObj[lang]["Components"]["Buttons"]["ChangeCurrency"]["name"]}</font> </a>
         </td>
      </tr>
      <br></br><br></br>
      <table align="left" width="100%">
      <tr>
        <td colspan="3"><br></br><br></br></td>
      </tr>
      <tr>
        <td width="60%">
          <font size="7" color="#003e4d">
           <form>
            <input type="text"
               style={{ width: "100%", fontSize: "42px",
                border: "4px solid", paddingTop: "3%",   paddingBottom: "3%",
                paddingLeft: "2.5%", borderColor: "rgb(0, 62, 77)",
                color: "rgb(139, 0, 0)"}}
                value={ethAmount}
                onChange={(e) => handleFormInput("ether", e.target.value)}
            />
          </form>
         </font>
        </td>
        <td style={{ paddingLeft: "1.55%", fontSize: "42px", color: "rgb(0, 62, 77)" }} align="right">
          ETC
        </td>
        <td width="20%"></td>
        <td width="20%"></td>
      </tr>
      <tr>
        <td colspan="3"><br></br></td>
      </tr>
      <tr>
        <td width="60%">
          <font size="7" color="#003e4d">
           <form>
            <input type="text"
               style={{ width: "100%", fontSize: "42px",
                border: "4px solid", paddingTop: "3%",   paddingBottom: "3%",
                paddingLeft: "2.5%", borderColor: "rgb(0, 62, 77)",
                color: "rgb(139, 0, 0)"}}
                value={tokenAmount}
                onChange={(e) => handleFormInput("token", e.target.value)}
           />
          </form>
         </font>
        </td>
        <td style={{ paddingLeft: "1.55%", fontSize: "42px", color: "rgb(139, 0, 0)" }} align="right">
          ТОК
        </td>
        <td width="20%"></td>
        <td width="20%"></td>
      </tr>
      <tr>
        <td colspan="3"><br></br></td>
      </tr>
      <tr>
        <td width="60%">
          <font size="7" color="#003e4d">
           <form>
            <input type="text"
               style={{ width: "100%", fontSize: "42px",
                border: "4px solid", paddingTop: "3%",   paddingBottom: "3%",
                paddingLeft: "2.5%", borderColor: "rgb(0, 62, 77)",
                color: "rgb(139, 0, 0)"}}
                value={cur4Amount && Math.floor(cur4Amount)}
                onChange={(e) => handleFormInput(currency4, e.target.value)}
           />
          </form>
         </font>
        </td>
        <td style={{ paddingLeft: "1.55%", fontSize: "42px", color: "rgb(0, 62, 77)" }} align="right">
          {currency4}
        </td>
        <td width="20%"></td>
        <td width="20%"></td>
      </tr>
      <tr>
        <td colspan="3"><br></br></td>
      </tr>
      <tr>
        <td width="60%">
          <font size="7" color="#003e4d">
           <form>
            <input type="text"
               style={{ width: "100%", fontSize: "42px",
                border: "4px solid", paddingTop: "3%",   paddingBottom: "3%",
                paddingLeft: "2.5%", borderColor: "rgb(0, 62, 77)",
                color: "rgb(139, 0, 0)"}}
                value={cur5Amount && Math.floor(cur5Amount)}
                onChange={(e) => handleFormInput(currency5, e.target.value)}
           />
          </form>
         </font>
        </td>
        <td style={{ paddingLeft: "1.55%", fontSize: "42px", color: "rgb(0, 62, 77)" }} align="right">
          {currency5}
        </td>
        <td width="20%"></td>
        <td width="20%"></td>
      </tr>
      <tr>
        <td colspan="3"><br></br><br></br><br></br><br></br></td>
      </tr>
    </table>
    </>
   )
}
