import React from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TopMenu, BottomMenu, SnackBar, LoginTimeout } from "../../components";
import { ROOT_PATH, API_PATH, MESSAGES, NormalizeText } from '../../constants';
import { isAddress, isPrivKey } from "../../util.js";
import { syncTexts } from "../../util"

export default function(props) {
   let history = useHistory();
   let token   = localStorage.getItem("token");
   let lang    = JSON.parse(localStorage.getItem("lang")) || "en";
   if (!token) history.push("/");

   let [addressFieldsText, setAddressFieldsText] = React.useState("");
   let [privKeyFieldsText, setPrivKeyFieldsText] = React.useState("");
   let [pubKeyFieldsText,  setPubKeyFieldsText]  = React.useState("");
   let [snackbar, setSnackbar] 	 = React.useState({ isVisible: false, text: "", color: "" });
   let [snackbar2, setSnackbar2] = React.useState({ isVisible: false, text: "", color: "" });
   let [textObj, setTextObj] 	 = React.useState("")
   let [genAddr, setGenAddr]     = React.useState("")
   let [privKey, setPrivKey]     = React.useState("")

   const [addressFields, setAddressFields] = React.useState({
      name: { isValidated: false, text: '' },
   });

   React.useEffect(async () => {
    if (!textObj) {
      setTextObj(JSON.parse(localStorage.getItem("texts")))
      let t = await syncTexts(); 
      setTextObj(t);
    }
   })

   let showCreatMsg = (e) => {
     document.getElementById("top_message").style.display = "block"
   }

   let hideCreatMsg = (e) => {
     document.getElementById("top_message").style.display = "none"
   }

   let showCreatMsg2 = (e) => {
     document.getElementById("top_message2").style.display = "block"
   }

   let hideCreatMsg2 = (e) => {
     document.getElementById("top_message2").style.display = "none"
   }

   let addAddress = () => {
     if (addressFieldsText.length > 20 || addressFieldsText.length == 0) {
       setSnackbar({
          text:      textObj[lang]["Components"]["Messages"]["addAddressRules"],
          isVisible: true,
          color:    "#2b2b2b",
       });
       return;
     }

     let user = JSON.parse(localStorage.getItem("user"));

     if (user.addresses.length >= 25) {
       setSnackbar({
          text:      textObj[lang]["Components"]["Messages"]["addrLimit"],
          isVisible: true,
          color:    "#2b2b2b",
       });
       return;
     }

     let res = 0;
     user.addresses.forEach((addr) => {
        if (addr && addressFieldsText === addr.name) res =  1;
     })

     if (res == 1) {
       setSnackbar({
          text:      textObj[lang]["Components"]["Messages"]["addAddressRules"],
          isVisible: true,
          color:    "#2b2b2b",
       });
       return;
     }

     axios
        .post(`${API_PATH}/user/address`, {
           login: user.login ,
           name:  NormalizeText(addressFieldsText) || "N/A",
           token: localStorage.getItem("token"),
           lang:  lang
         })
        .then(({ data }) => {
           if (data.status == "success") {
              let newAddr = data.address;
              setGenAddr(newAddr.address)
              setPrivKey(newAddr.rawKey)
              newAddr.rawKey = ""
              user.addresses.push(newAddr);
              user.addresses.length++;
              localStorage.setItem("user", JSON.stringify(user))
              showCreatMsg()
           } else {
              setSnackbar2({ isVisible: true, text: data.message, color: "#2b2b2b" });
           }
        })
        .catch((err, data) => {
          setSnackbar2({ 
          	isVisible: true, 
          	text:      textObj[lang]["Components"]["Messages"]["errCreateAddress"],
            color:     "#2b2b2b"
          });
          console.log(err);
        });
   }

   let addFromKeys = () => {
     let usr = JSON.parse(localStorage.getItem("user"));

     if (usr.addresses.length >= 25) {
        setSnackbar({
           text:      textObj[lang]["Components"]["Messages"]["addrLimit"],
           isVisible: true,
           color:    "#2b2b2b",
        });
        return;
     }

    if (!pubKeyFieldsText || addressFieldsText.length == 0) {
      setSnackbar({
        text:      textObj[lang]["Components"]["Messages"]["addAddressRules"],
        isVisible: true,
        color:    "#2b2b2b",
      });
      return;
    }

    if(pubKeyFieldsText && !isAddress(pubKeyFieldsText)) {
      setSnackbar({
         text:      textObj[lang]["Components"]["Messages"]["addAddressRules"],
         isVisible: true,
         color:    "#2b2b2b",
      });
      return;
    }

    let user = JSON.parse(localStorage.getItem("user"));
    let pubKeyString = pubKeyFieldsText

    if (pubKeyString.substring(0,2) != "0x")
      pubKeyString = "0x" + pubKeyFieldsText

     axios
        .post(`${API_PATH}/user/address`, {
           login:    user.login ,
           privKey:  privKeyFieldsText || "N/A",
           pubKey:   pubKeyString,
           token:    localStorage.getItem("token"),
           name:     addressFieldsText || "N/A",
           lang:     lang
         })
        .then(({ data }) => {
           if (data.status == "success") {
              let newAddr = data.address;
              setGenAddr(newAddr.address)
              setPrivKey(newAddr.rawKey)
              newAddr.rawKey = ""
              user.addresses.push(newAddr);
              user.addresses.length++;
              localStorage.setItem("user", JSON.stringify(user))
              
              if (newAddr.privateKey !== "N/A")
                showCreatMsg()
              else
                setSnackbar2({ isVisible: true, text: data.message, color: "#2b2b2b" });
           } else {
              setSnackbar2({ isVisible: true, text: data.message, color: "#2b2b2b" });
           }
        })
        .catch((err, data) => {
          setSnackbar2({ 
          	isVisible: true, 
          	text:      textObj[lang]["Components"]["Messages"]["errCreateAddress"],
            color:     "#2b2b2b"
          });
          console.log(err)
        });
   }

   return (
     <>
        <br></br><br></br><br></br>
        <TopMenu
          navLink   = "/address/all"
          pageTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["AddAddress"]["name"]}
          menuTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["AddAddress"]["title"]}
          icon      = "<">
        </TopMenu>
        <div
          className="top_message"
          id="top_message"
          align="center"
          width="50%"
          style={{display: "none", maxHeight: "max-content"}}>
          <br></br>
          {textObj && textObj[lang]["PageText"]["AddressAddPage"][0]}
          <br></br> <br></br>
          <button
               style={{cursor: "pointer", fontSize: "0.8em", paddingTop: "1.8%", paddingBottom: "1.8%",
                fontWeight: "500", background: "#c45331", color: "#fff", minWidth: "55%",
                paddingLeft: "10%", paddingRight: "10%", borderColor: "#fff",
                border: "3px solid"}}
               align="center"
               className="box1003"
               title={privKey.substring(privKey.length - 4, privKey.length)}
            >
               {privKey.substring(privKey.length - 4, privKey.length)}
          </button>
          <br></br> <br></br>
          <CopyToClipboard text={privKey.substring(privKey.length - 4, privKey.length)}>         
            <button
               style={{cursor: "pointer", fontSize: "0.8em", paddingTop: "1.8%", paddingBottom: "1.8%",
                fontWeight: "500", background: "#c45331", color: "#fff", minWidth: "55%",
                paddingLeft: "10%", paddingRight: "10%", borderColor: "#fff",
                border: "3px solid"}}
               align="center"
               className="box1003"
               title={textObj && textObj[lang]["Components"]["Buttons"]["CopyPasswd"]["name"]}
               onClick={() => { 
                hideCreatMsg();
                showCreatMsg2();
               }}
            >
               {textObj && textObj[lang]["Components"]["Buttons"]["CopyPasswd"]["name"]}
            </button>
          </CopyToClipboard>
          <br></br><br></br>
            <button
               style={{cursor: "pointer", fontSize: "0.8em", paddingTop: "1.8%", paddingBottom: "1.8%",
                fontWeight: "500", background: "#c45331", color: "#fff", minWidth: "55%",
                paddingLeft: "10%", paddingRight: "10%", borderColor: "#fff",
                border: "3px solid"}}
               align="center"
               className="box1003"
               title={textObj && textObj[lang]["Components"]["Buttons"]["Close"]["name"]}
               onClick={() => { 
                hideCreatMsg();
                showCreatMsg2();
               }}
            >
               {textObj && textObj[lang]["Components"]["Buttons"]["Close"]["name"]}
            </button>
          <br></br>
        </div>
        <div
          className="top_message"
          id="top_message2"
          align="center"
          width="50%"
          style={{display: "none", maxHeight: "max-content"}}>
          <br></br>
          {textObj && textObj[lang]["PageText"]["AddressAddPage"][1]}
          <br></br> <br></br>   
          <button
            style={{cursor: "pointer", fontSize: "0.8em", paddingTop: "1.8%", paddingBottom: "1.8%",
              fontWeight: "500", background: "#c45331", color: "#fff", minWidth: "55%",
              paddingLeft: "10%", paddingRight: "10%", borderColor: "#fff",
              border: "3px solid"}}
            align="center"
            className="box1003"
            title={textObj && textObj[lang]["Components"]["Buttons"]["Close"]["name"]}
            onClick={() => { 
              hideCreatMsg2();
              history.push("/address/all")
            }}
          >
            {textObj && textObj[lang]["Components"]["Buttons"]["Close"]["name"]}
          </button>
        </div>
        <div style={{ minHeight: "100%" }}>       
        <table align="center" width="80%">
         <tbody>
          <tr>
            <td colspan="3"><br></br><br></br><br></br><br></br><br></br><br></br></td>
          </tr>
          <tr>
          <td align="left">
            <font size="7" color="#8B0000">{textObj && textObj[lang]["Components"]["FieldTitles"]["SetAddrName"]}</font>
            <form>
              <br></br><br></br>
              <input
                style={{ width: "85%", color: "#003e4d", fontSize: "38px", padding: "2.8%" }}
                className="box1003"
                type="text"
                placeholder={textObj && textObj[lang]["Components"]["Placeholders"]["SetAddrName"]["name"]}
                id="addrName"
                maxLength="20"
                value={addressFieldsText}
                onChange={(e) => setAddressFieldsText(e.target.value)}
              />
            </form>
            <br></br><br></br>
            <font size="7" color="#8B0000">{textObj && textObj[lang]["Components"]["FieldTitles"]["SetPubKey"]}</font>
            <form>
              <br></br><br></br>
              <input
                style={{ width: "85%", color: "#003e4d", fontSize: "38px", padding: "2.8%" }}
                className="box1003"
                type="text"
                placeholder={textObj && textObj[lang]["Components"]["Placeholders"]["SetPubKey"]["name"]}
                id="addrName"
                maxLength="128"
                value={pubKeyFieldsText}
                onChange={(e) => setPubKeyFieldsText(e.target.value)}
              />
            </form>
            <br></br><br></br>
            <form>
              <font size="7" color="#8B0000">{textObj && textObj[lang]["Components"]["FieldTitles"]["SetPrivKey"]}</font>
              <br></br><br></br><br></br>
              <input
                style={{ width: "85%", color: "#003e4d", fontSize: "38px", padding: "2.8%" }}
                className="box1003"
                type="text"
                placeholder={textObj && textObj[lang]["Components"]["Placeholders"]["SetPrivKey"]["name"]}
                maxLength="128"
                value={privKeyFieldsText}
                onChange={(e) => setPrivKeyFieldsText(e.target.value)}
              />
              <br></br><br></br><br></br><br></br>
              <button
                  style={{cursor: "pointer", fontSize: "44px", padding: "2.4%",
                  background: "#ffffff", color: "#003e4d", width: "100%"}}
                  align="center"
                  className="box1003"
                  onClick={(e) => { e.preventDefault();  addFromKeys(); }}
                  title={textObj && textObj[lang]["Components"]["Buttons"]["AddAddress"]["title"]}>
                  {textObj && textObj[lang]["Components"]["Buttons"]["AddAddress"]["name"]}
              </button>
             </form>
            </td>
          </tr>
         </tbody>
        </table>
        <SnackBar
          text={snackbar.text}
          isVisible={snackbar.isVisible}
          lang={lang}
          textObj={textObj}
          timeOut={4000}
          setVisible={v => setSnackbar({ ...snackbar, isVisible: v })}
        />
        <SnackBar
          lang={lang}
          textObj={textObj}
          text={snackbar2.text}
          isVisible={snackbar2.isVisible}
          redir={"/address/all"}
          setVisible={v => setSnackbar({ ...snackbar2, isVisible: v })}
        />
        <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
        <TopMenu
          navLink   = "/address/all"
          pageTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["CreateAddress"]["name"]}
          menuTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["CreateAddress"]["title"]}
          icon      = "">
        </TopMenu>
        <table align="center" width="80%">
         <tbody>
          <tr>
            <td colspan="3"><br></br><br></br><br></br><br></br><br></br><br></br></td>
          </tr>
          <tr>
          <td align="left">
            <font size="7" color="#8B0000">{textObj && textObj[lang]["Components"]["FieldTitles"]["SetAddrName"]}</font>
            <form>
              <br></br><br></br>
              <input
                style={{ width: "85%", color: "#003e4d", fontSize: "38px", padding: "2.8%" }}
                className="box1003"
                type="text"
                placeholder={textObj && textObj[lang]["Components"]["Placeholders"]["SetAddrName"]["name"]}
                id="addrName"
                maxLength="20"
                value={addressFieldsText}
                onChange={(e) => setAddressFieldsText(e.target.value)}
              />
              <br></br><br></br><br></br><br></br>
              <button
                style={{cursor: "pointer", fontSize: "44px", padding: "2.4%",
                  background: "#ffffff", color: "#003e4d", width: "100%"}}
                align="center"
                className="box1003"
                onClick={(e) => { e.preventDefault();  addAddress(); }}
                title={textObj && textObj[lang]["Components"]["Buttons"]["CreateAddress"]["title"]}>
              {textObj && textObj[lang]["Components"]["Buttons"]["CreateAddress"]["name"]}
             </button>
            </form>
           </td>
          </tr>
         </tbody>
        </table>
       </div>
       <br></br><br></br><br></br><br></br><br></br><br></br>
      <BottomMenu lang={lang} textObj={textObj}/>
     <LoginTimeout/>
    </>
   )
}
