import React from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { syncTexts } from "../../util"

export default function (props) {
   let history = useHistory();
   let {r}     = useParams();
   let lang    = JSON.parse(localStorage.getItem("lang")) || "en";
   let [textObj, setTextObj] = React.useState("");

   // Засчитываем реф ссылку только когда это первая
   // ссылка, по которой пришел посетитель. В случае
   // перехода в последствии по другим реф ссылкам
   // все равно засчитана будет самая первая.

   let prevParent = localStorage.getItem("referrer");
   if (r && !prevParent) localStorage.setItem("referrer", r);

   React.useEffect(async () => {
      if (!textObj) {
        setTextObj(JSON.parse(localStorage.getItem("texts")))
        let t = await syncTexts(); 
        setTextObj(t);
      }
   })

   return (
      <>
        <br></br><br></br>
          <table align="right" 
         		style={{
         			width: "auto",
    				zIndex: "1000",
    				position: "absolute",
    				top: "0",
    				right: "20px",
    				background: "#fff",
    				textAlign: "left"
         		}}>
          <div style={{cursor: "pointer"}}
          	   onClick={(e) => {
				  (document.getElementById("dropdown").style.display === "block") ?       	
          		   document.getElementById("dropdown").style.display = "none"     :
          		   document.getElementById("dropdown").style.display = "block"
          	   }} 
          	   id="dd" 
          	   class="wrapper-dropdown-5">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			<font size="6" color="#003e4d">{
				(lang === "en") ? "English" :
				(lang === "ja") ? "日本語" :
				(lang === "fr") ? "Français" :
				(lang === "ko") ? "한국어" : 
				(lang === "ru") ? "Русский" :
				(lang === "es") ? "Español" :
				(lang === "zh") ? "中國人" :
				(lang === "pt") ? "Português" :
				(lang === "hi") ? "हिन्" : 
				(lang === "de") ? "Deutsche" : 
				(lang === "pl") ? "Polskie" : ""
			}</font>
          	<font size="6" color="#8B0000"> NEC2020</font>
			<ul id="dropdown" class="dropdown" style={{display: "none"}}>
				<li style={{listStyle: "none", fontSize: "32px"}}><a onClick={() => { lang = "en"; localStorage.setItem("lang", JSON.stringify(lang)); window.location.reload() }} href="#">English </a></li>
				<li style={{listStyle: "none", fontSize: "32px"}}><a onClick={() => { lang = "ja"; localStorage.setItem("lang", JSON.stringify(lang)); window.location.reload() }} href="#">日本語 </a></li>
				<li style={{listStyle: "none", fontSize: "32px"}}><a onClick={() => { lang = "fr"; localStorage.setItem("lang", JSON.stringify(lang)); window.location.reload() }} href="#">Français </a></li>
				<li style={{listStyle: "none", fontSize: "32px"}}><a onClick={() => { lang = "ko"; localStorage.setItem("lang", JSON.stringify(lang)); window.location.reload() }} href="#">한국어 </a></li>
				<li style={{listStyle: "none", fontSize: "32px"}}><a onClick={() => { lang = "ru"; localStorage.setItem("lang", JSON.stringify(lang)); window.location.reload() }} href="#">Русский </a></li>
				<li style={{listStyle: "none", fontSize: "32px"}}><a onClick={() => { lang = "es"; localStorage.setItem("lang", JSON.stringify(lang)); window.location.reload() }} href="#">Español </a></li>
				<li style={{listStyle: "none", fontSize: "32px"}}><a onClick={() => { lang = "zh"; localStorage.setItem("lang", JSON.stringify(lang)); window.location.reload() }} href="#">中國人 </a></li>
				<li style={{listStyle: "none", fontSize: "32px"}}><a onClick={() => { lang = "pt"; localStorage.setItem("lang", JSON.stringify(lang)); window.location.reload() }} href="#">Português </a></li>
				<li style={{listStyle: "none", fontSize: "32px"}}><a onClick={() => { lang = "hi"; localStorage.setItem("lang", JSON.stringify(lang)); window.location.reload() }} href="#">हिन्दी </a></li>
				<li style={{listStyle: "none", fontSize: "32px"}}><a onClick={() => { lang = "de"; localStorage.setItem("lang", JSON.stringify(lang)); window.location.reload() }} href="#">Deutsche </a></li>
				<li style={{listStyle: "none", fontSize: "32px"}}><a onClick={() => { lang = "pl"; localStorage.setItem("lang", JSON.stringify(lang)); window.location.reload() }} href="#">Polskie </a></li>
			</ul>
		  </div>
		  </table>
          <table align="center" width="97.5%" style={{marginTop: "20px"}}>
             <tr>
                <td
                  class="box1001"
                  align="center"
                  onClick={() => { history.push('/info'); window.location.reload(); }}
                  style={{cursor: "pointer"}}
                  title={textObj && textObj[lang]["Components"]["StartMenu"]["Info"]["title"]}
                  bgcolor="white">
                   <a title={textObj && textObj[lang]["Components"]["StartMenu"]["Info"]["title"]}
                      style={{cursor: "pointer"}}>
                      <br></br><br></br><br></br>
                      <font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["StartMenu"]["Info"]["name"]}</font>
                      <br></br><br></br><br></br><br></br>
                   </a>
                </td>
             </tr>
             <tr>
                <td
                  align="center"
                  onClick={() => { history.push('/login'); }}
                  style={{cursor: "pointer"}}
                  bgcolor="#003e4d"
                  title={textObj && textObj[lang]["Components"]["StartMenu"]["Login"]["title"]}
                >
                    <a title={textObj && textObj[lang]["Components"]["StartMenu"]["Login"]["title"]}>
                       <br></br><br></br><br></br>
                       <font size="7" color="white">{textObj && textObj[lang]["Components"]["StartMenu"]["Login"]["name"]}</font>
                       <br></br><br></br><br></br><br></br>
                    </a>
                </td>
             </tr>
             <tr>
                <td
                  class="box1002"
                  align="center"
                  onClick={() => { history.push('/manual'); }}
                  style={{cursor: "pointer"}}
                  bgcolor="white"
                  title={textObj && textObj[lang]["Components"]["StartMenu"]["Manual"]["title"]}>
                    <a title={textObj && textObj[lang]["Components"]["StartMenu"]["Manual"]["title"]}>
                       <br></br><br></br><br></br>
                       <font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["StartMenu"]["Manual"]["name"]}</font>
                       <br></br><br></br><br></br><br></br>
                    </a>
                </td>
             </tr>
          </table>
      </>
   )
}
