export default function NormalizeText(text) {
  if (!text) return "";
  let map = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    "&#039;": "'"
  };
  try {
    return text.replace(/&amp;|&lt;|&gt;|&quot;|&#039;/g, function (m) { return map[m]; });
  } catch (error) {
    return text;
  }
}