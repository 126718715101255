import React from 'react';
import { useHistory } from "react-router-dom";

export default function (props) {
  let history  = useHistory();
  let lang = props.lang || "en"

  return (
    <div
      className={(props.isVisible ? "snackbar-visible" : "snackbar")}
      onClick={() => { props.setVisible(false); if (props.redir) history.push(props.redir); window.location.reload();}}
      style={{width: "50%", maxWidth: "50%",  maxHeight: "480px", margin: "0px auto"}}>
     <br></br>
      <div style={{minHeight: "60%", wordWrap: "break-word"}}>{props.text}</div>
      <button
         style={{cursor: "pointer", fontSize: "0.8em", paddingTop: "1.8%", paddingBottom: "1.8%",
           fontWeight: "500", background: "#c45331", color: "#fff", minWidth: "55%",
           paddingLeft: "10%", paddingRight: "10%", marginTop: "25px", borderColor: "#fff",
           border: "3px solid"}}
         align="center"
         className="box1003"
         title={props.textObj && props.textObj[lang]["Components"]["Buttons"]["Close"]["title"]}>
       {props.textObj && props.textObj[lang]["Components"]["Buttons"]["Close"]["name"]}
      </button>
    </div>
  )
}
