import React from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { TopMenu, BottomMenu, SnackBar, LoginTimeout } from "../../components";
import { ROOT_PATH, API_PATH } from '../../constants';
import { syncTexts } from "../../util"

export default function (props) {
  let history = useHistory();
  let token   = localStorage.getItem("token");
  if (!token) history.push("/");
  let usr  = JSON.parse(localStorage.getItem("user"));
  let lang = JSON.parse(localStorage.getItem("lang")) || "en";

  let [textObj, setTextObj]   = React.useState("")
  let [snackbar, setSnackbar] = React.useState({ isVisible: false, text: "", color: "" });
  let [initial, setInitial]   = React.useState(0);
  let [coords, setCoords]     = React.useState(0);
  let [rmAddr, setRmAddr]     = React.useState(0);

  let removeAddress = (addr) => {
    setSnackbar({ 
    	isVisible: true,
    	text: textObj[lang]["Components"]["Messages"]["addr"] + textObj[lang]["Components"]["Messages"]["removing"], 
    	color: "#2b2b2b" 
    });

    axios
       .post(`${API_PATH}/user/del_address`, {
          login:    usr.login ,
          address:  addr,
          token:    localStorage.getItem("token")
        })
       .then(({ data }) => {
          if (data.status == "success") {
             let usr = data.user;
             localStorage.setItem("user", JSON.stringify(usr))
             setSnackbar({ 
             	isVisible: true,
             	text: textObj[lang]["Components"]["Messages"]["addrSuccessRemoved"],
             	color: "#2b2b2b"
             });
          } else {
             console.log(data.message);
          }
       })
       .catch((err, data) => {
         console.log(err);
       });
  }

  let renderAddresses = () => {
    var r = new Array();
    (usr.addresses.length === 0)
     ?
       r.push(
         <table align="center" width="100%">
         <br></br><br></br><br></br><br></br><br></br>
          <tbody>
           <tr align="center">
              <td title={textObj && textObj[lang]["Components"]["TopMenu"]["AddressPage"]["title"]} onClick={() => { history.push("/add_address"); }} style={{ cursor: "pointer", fontWeight: "500" }}>
                <font size="6" color="#003e4d">
                    {textObj && textObj[lang]["PageText"]["AllAddressPage"][0]} <br></br> {textObj && textObj[lang]["PageText"]["AllAddressPage"][1]} <br></br><br></br>
                    <img src={`${ROOT_PATH}/izo/+.png`}/>
                 </font>
              </td>
           </tr>
          </tbody>
          <br></br><br></br><br></br>
         </table>
       )
     :
       usr.addresses.forEach((addr, i) => {
        if (addr) {
          let s = new String(addr.name || addr.address);
          let a = new String(addr.address);
          r.push(
           <DragDropContainer
			   dragData={addr}
               onDragEnd={onDragEnd}
           >
            <a
               onMouseMove={(e) => {
				    setCoords({ x: e.screenX, y: e.screenY });
			   }}
               onTouchMove={(e) => {
				    setCoords({ x: e.changedTouches[0].pageX, y: e.changedTouches[0].pageY});
			   }}
			   onMouseDown={(e) => {
				    setInitial({ x: e.screenX, y: e.screenY });
			   }}
               onClick={() => {
                 history.push("/address/" + addr.address);
                 window.location.reload();
               }}
               title={ s }
               style={{cursor: "pointer"}}>
              <font size="7" color="#8B0000"><br></br>{addr.name}<br></br></font>
            </a>
         </DragDropContainer>
         )
        }
      })
      return r
  }

  let onDragEnd = (e) => {
    let res = initial.x - coords.x;

    if (res >= 20 || coords.x <= 40) {
       document.getElementById("top_message").style.display="block";
       usr.addresses.forEach((addr, c) => {
          if (addr && addr.address == e.address) {
             setRmAddr(addr.address);
             return;
          }
       });
    }

    setInitial({ x: 0, y: 0 });
  }

  React.useEffect(async () => {
    if (!textObj) {
      setTextObj(JSON.parse(localStorage.getItem("texts")))
      let t = await syncTexts(); 
      setTextObj(t);
    }
  })


  return (
    <>
    <div style={{ minHeight: "1100px" }}>
    <br></br><br></br>
    <TopMenu
      navLink   = "/add_address"
      pageTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["AddressPage"]["name"]}
      menuTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["AddressPage"]["title"]}
      icon      = "+">
    </TopMenu>
    <div
      className="top_message"
      id="top_message"
      align="center"
      width="50%"
      style={{display: "none", maxHeight: "max-content"}}>
      <br></br>
      <button
         onClick={(e) => { removeAddress(rmAddr); document.getElementById("top_message").style.display="none" }}
         style={{cursor: "pointer", fontSize: "0.8em", paddingTop: "1.8%", paddingBottom: "1.8%",
           fontWeight: "500", background: "#c45331", color: "#fff", minWidth: "55%",
           paddingLeft: "10%", paddingRight: "10%", borderColor: "#fff",
           border: "3px solid"}}
         align="center"
         className="box1003"
         title={textObj && textObj[lang]["Components"]["Buttons"]["RemoveAddr"]["title"]}>
        {textObj && textObj[lang]["Components"]["Buttons"]["RemoveAddr"]["name"]}
      </button>
      <br></br> <br></br>
      <button
         onClick={(e) => { document.getElementById("top_message").style.display="none"}}
         style={{cursor: "pointer", fontSize: "0.8em", paddingTop: "1.8%", paddingBottom: "1.8%",
           fontWeight: "500", background: "#c45331", color: "#fff", minWidth: "55%",
           paddingLeft: "10%", paddingRight: "10%", borderColor: "#fff",
           border: "3px solid"}}
         align="center"
         className="box1003"
         title={textObj && textObj[lang]["Components"]["Buttons"]["Close"]["title"]}>
       {textObj && textObj[lang]["Components"]["Buttons"]["Close"]["name"]}
      </button>
    </div>
    <table align="center" width="80%">
     <tbody>
      <tr align="left">
        <td>
           { renderAddresses() }
        </td>
      </tr>
     </tbody>
    </table>
    <SnackBar
      text={snackbar.text}
      lang={lang}
      textObj={textObj}
      isVisible={snackbar.isVisible}
      timeOut={4000}
      setVisible={v => setSnackbar({ ...snackbar, isVisible: v })}
    />
    <table align="center" width="80%">
     <tbody>
      <tr>
        <td colspan="3"><br></br><br></br><br></br></td>
      </tr>
      <tr>
        <td align="center"
            class="box1003"
            width="100%"
            onClick={() => { history.push("/"); }}
            title={textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["title"]}>
              <a style={{ cursor: "pointer" }} title={textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["title"]}><font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["StartPage"]["name"]}</font> </a>
        </td>
        <td width="20%"></td>
      </tr>
      <br></br><br></br>
      <tr>
        <td align="center"
            class="box1003"
            width="100%"
            onClick={() => { window.location.replace("https://www.youtube.com/results?search_query=QUADRATE+Ecosystem"); }}
            title="YouTube">
              <a style={{ cursor: "pointer" }} title="YouTube">
                <font size="7" color="#003e4d">YouTube</font>
              </a>
        </td>
        <td width="20%"></td>
      </tr>
      <tr>
        <td colspan="3"><br></br><br></br><br></br><br></br></td>
      </tr>
     </tbody>
    </table>
   </div>
  <BottomMenu lang={lang} textObj={textObj}/>
 <LoginTimeout/>
</>
  )
}
