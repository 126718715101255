import React from "react";
import axios from 'axios';
import { HashRouter, Switch, Route, } from "react-router-dom";
import './App.css';
import { TokenCheck } from './components';
import { API_PATH } from './constants';
import { AllAddressPage, ChangeCurrencyPage, ChangePasswordPage,
         ContractPage, InfoPage, LoginPage,  ManualPage,
         RegisterPage, FormPage, SettingsPage, SingleAddressPage,
         StartPage, AddressAddPage, SendTokenPage, ExchangeTokenPage, TransferPage }
       from './pages';

export default function () {
   const [token, setToken] = React.useState("");

   // Роутер

   return (
     <HashRouter>
       <Switch>
         <Route path="/address/all">
           <TokenCheck
             token={token}
             setToken={v => setToken(v)}
           >
             <AllAddressPage
               token={token}
             />
           </TokenCheck>
         </Route>
         <Route path="/address/:h">
           <TokenCheck
             token={token}
             setToken={v => setToken(v)}
           >
             <SingleAddressPage
               token={token}
             />
           </TokenCheck>
         </Route>
         <Route path="/transfer/:h">
           <TokenCheck
             token={token}
             setToken={v => setToken(v)}
           >
             <TransferPage
               token={token}
             />
           </TokenCheck>
         </Route>
         <Route path="/send_token/:h">
           <TokenCheck
             token={token}
             setToken={v => setToken(v)}
           >
             <SendTokenPage
               token={token}
             />
           </TokenCheck>
         </Route>
         <Route path="/exchange/:h">
           <TokenCheck
             token={token}
             setToken={v => setToken(v)}
           >
             <ExchangeTokenPage
               token={token}
             />
           </TokenCheck>
         </Route>
         <Route path="/settings" exact render={(props) =>
           <TokenCheck
             token={token}
             setToken={v => setToken(v)}
           >
             <SettingsPage
               token={token}
             />
           </TokenCheck>
         } />
         <Route path="/contract">
           <TokenCheck
             token={token}
             setToken={v => setToken(v)}
           >
             <ContractPage
               token={token}
             />
           </TokenCheck>
         </Route>
         <Route path="/info">
             <InfoPage
               token={token}
             />
         </Route>
         <Route path="/form">
             <FormPage
               token={token}
             />
         </Route>
         <Route path="/manual">
           <ManualPage
             token={token}
           />
         </Route>
         <Route path="/currency/:h">
            <ChangeCurrencyPage
               token={token}
            />
         </Route>
         <Route path="/add_address/">
           <TokenCheck
             token={token}
             setToken={v => setToken(v)}
           >
            <AddressAddPage
               token={token}
            />
           </TokenCheck>
         </Route>
         <Route path="/password">
           <TokenCheck
             token={token}
             setToken={v => setToken(v)}
           >
            <ChangePasswordPage
               token={token}
            />
           </TokenCheck>
         </Route>
         <Route path="/login">
            <LoginPage
               setToken={v => setToken(v)}
               token={token}
            />
         </Route>
         <Route path="/register">
            <RegisterPage
               token={token}
            />
         </Route>
         <Route path="/:r?">
             <StartPage
               token={token}
             />
         </Route>
       </Switch>
     </HashRouter>
   );
}
