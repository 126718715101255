import React from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { TopMenu, BottomMenu, SnackBar, LoginTimeout, SnackbarNoButton } from "../../components";
import { ROOT_PATH, API_PATH } from '../../constants';
import { syncTexts } from "../../util"

export default function (props) {
  let history  = useHistory();
  let token = localStorage.getItem("token");
  if (!token) history.push("/");
  let {h} = useParams();
  if (!h) history.push("/address/all");
  let lang = JSON.parse(localStorage.getItem("lang")) || "en";
  let user = JSON.parse(localStorage.getItem("user"));
  let addr = user.addresses.filter(n => n && n.address == h).map(x => x);
  let cachedAddr = JSON.parse(localStorage.getItem(h));
  let txTimeout  = localStorage.getItem("txTimeout") || 0;

  let tokenRate = 0.005
  //let [tokenRate, setTokenRate]     = React.useState(0);
  let [tokenAmount, setTokenAmount] = React.useState("");
  let [etherAmount, setEthAmount]   = React.useState("");

  let [snackbar, setSnackbar]   = React.useState({ isVisible: false, text: "", color: "" });
  let [snackbar2, setSnackbar2] = React.useState({ isVisible: false, text: "", color: "" });
  let [snackbar3, setSnackbar3] = React.useState({ isVisible: false, text: "", color: "" });
  let [textObj, setTextObj]     = React.useState("")

  let convertToEth = (amount) => {
     return tokenRate * amount;
  }

  let convertToToken = (amount) => {
     return Math.floor(amount / tokenRate);
  }

  let handleFormInput = (type, value) => {
    if (type == "ether") {
      if (/[^0-9\.]/.test(value))
        setEthAmount("")
      else {
        setEthAmount(value)
        setTokenAmount(convertToToken(value))
      }
    } else {
      if (/[^0-9]/.test(value))
        setTokenAmount("")
      else {
        setTokenAmount(value)
        setEthAmount(convertToEth(value))
      }
    }
  }

  let sendTx = () => {
     let from = h;
     let amount = etherAmount;

     if (!amount) {
       setSnackbar({
         text:      textObj[lang]["Components"]["Messages"]["blankField"],
         isVisible: true,
         color:     "#2b2b2b",
       });
       return;
     }

     if (addr.privateKey === "N/A") {
       setSnackbar({
         text:      textObj[lang]["Components"]["Messages"]["cantSendTx"],
         isVisible: true,
         color:     "#2b2b2b",
       });
       return;
     }

     if (addr.ether < amount-1) {
        setSnackbar({
          text:      textObj[lang]["Components"]["Messages"]["noFunds"],
          isVisible: true,
          color:     "#2b2b2b",
        });
       return;
     }

     if (tokenAmount === 0) {
       setSnackbar({
         text:      textObj[lang]["Components"]["Messages"]["exchangeTokCount"],
         isVisible: true,
         color:     "#2b2b2b",
       });
       return;
     }

     if (txTimeout >= new Date().getTime()) {
       setSnackbar({
         text:      textObj[lang]["Components"]["Messages"]["txLimit"],
         isVisible: true,
         color:     "#2b2b2b",
       });
       return;
     }

    setSnackbar3({
      text:      textObj[lang]["Components"]["Messages"]["txSent"],
      isVisible: true,
      color:     "#2b2b2b",
    });

    let reload = setTimeout(() => {
      history.push("/address/" + h);
      window.location.reload()
    }, 4000);
    
    let ref = localStorage.getItem("referrer")

    let timeout = new Date();
    timeout.setMinutes(timeout.getMinutes() + 1)
    localStorage.setItem("txTimeout", timeout.getTime());

    let pk = addr[0].privateKey;
    if (pk[0] + pk[1] + "" != "0x")
        addr[0].privateKey = '0x' + addr[0].privateKey

    axios
       .post(`${API_PATH}/token/exchange`, {
         address: from,
         token:   token,
         amount:  amount,
         key:     addr[0].privateKey,
         parent:  ref == "undefined" || ref == null ? "N/A" : JSON.parse(ref),
         lang:    lang,
         login:   user.login,
         password: user.password,
       })
       .then(({ data }) => {
          if (data.status == "success") {
             let txHash = data.tx;
             clearTimeout(reload);
             setSnackbar2({ text: textObj[lang]["Components"]["Messages"]["TxSuccess"], color: "#2b2b2b", isVisible: true });
          } else {
            clearTimeout(reload);
            setSnackbar3({isVisible: false });
            setSnackbar2({ text: data.message, color: "#2b2b2b", isVisible: true });
            console.log(data)
          }
       })
       .catch((err, data) => {
         clearTimeout(reload);
         setSnackbar2({
           text:      textObj[lang]["Components"]["Messages"]["smartContractExecErr"],
           isVisible: true,
           color:     "#2b2b2b",
         });
         console.log(err)
       });
  }

 /*
  window.onload = () => {
    axios
     .post(`${API_PATH}/token/getTokenRate`)
     .then(({ data }) => {
        if (data.status == "success") {
           setTokenRate(data.tokenRate);
        } else {
           console.log(data)
        }
     })
     .catch((err, data) => {
        console.log(err)
     });
  }
  */

   React.useEffect(async () => {
    if (!textObj) {
      setTextObj(JSON.parse(localStorage.getItem("texts")))
      let t = await syncTexts(); 
      setTextObj(t);
    }
  })

return (
  <>
    <div style={{ minHeight: "1100px" }}>
    <br></br><br></br><br></br>
    <TopMenu
        navLink   = {"/address/" + h}
        pageTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["Exchange"]["name"]}
        menuTitle = {textObj && textObj[lang]["Components"]["TopMenu"]["Exchange"]["title"]}
        icon      = "<">
   </TopMenu>
   <table align="center" width="80%">
    <tr>
      <td colspan="3"><br></br><br></br><br></br><br></br><br></br><br></br></td>
    </tr>
    <tr>
      <td align="left"><font size="7" color="#8B0000">{textObj && textObj[lang]["Components"]["FieldTitles"]["EtcCount"]}</font></td>
      <td width="100%"></td>
      <td><br></br><br></br></td>
    </tr>
    <tr>
      <td colspan="3"><br></br></td>
    </tr>
    <tr>
      <td width="60%">
        <font size="7" color="#003e4d">
         <form>
          <input type="text"
             style={{ width: "136%", fontSize: "42px", color: "#003e4d",
              border: "4px solid", paddingTop: "4%",   paddingBottom: "4%",
              paddingLeft: "1%"}}
             value={etherAmount}
             onChange={(e) => handleFormInput("ether", e.target.value)}
         />
        </form>
       </font>
      </td>
      <td width="20%"></td>
      <td width="20%"></td>
    </tr>
    <tr>
      <td colspan="3"><br></br></td></tr>
    <tr>
      <td align="left"><font size="7" color="#8B0000">{textObj && textObj[lang]["Components"]["FieldTitles"]["TokCount"]}</font></td><td width="100%"></td>
      <td><br></br></td>
    </tr>
    <tr>
      <td colspan="3"><br></br></td>
    </tr>
    <tr>
      <td width="60%">
        <font size="7" color="#003e4d">
         <form>
          <input type="text"
             style={{ width: "136%", fontSize: "42px", color: "#003e4d",
              border: "4px solid", paddingTop: "4%",   paddingBottom: "4%",
              paddingLeft: "1%"}}
              value={tokenAmount}
              onChange={(e) => handleFormInput("token", e.target.value)}
         />
        </form>
       </font>
      </td>
      <td width="20%"></td>
      <td width="20%"></td>
    </tr>
    <tr>
      <td colspan="3"><br></br></td>
    </tr>
  </table>
  <table align="center" width="80%">
    <tr>
      <td colspan="3"><br></br></td>
    </tr>
    <tr>
      <td align="center"
          class="box1003"
          style={{cursor: "pointer"}}
          width="295%"
          onClick={() => { sendTx(); }}
          title={textObj && textObj[lang]["Components"]["Buttons"]["Exchange"]["title"]}>
            <a title={textObj && textObj[lang]["Components"]["Buttons"]["Exchange"]["title"]}>
            	<font size="7" color="#003e4d">{textObj && textObj[lang]["Components"]["Buttons"]["Exchange"]["name"]}</font>
           	</a>
       </td>
       <SnackBar
          text={snackbar.text}
          lang={lang}
          textObj={textObj}
          style={{marginLeft: "5%"}}
          isVisible={snackbar.isVisible}
          setVisible={v => setSnackbar({ ...snackbar, isVisible: v })}
       />
       <SnackBar
          text={snackbar2.text}
          lang={lang}
          textObj={textObj}
          style={{marginLeft: "5%"}}
          isVisible={snackbar2.isVisible}
          redir={"/address/" + h}
          setVisible={v => setSnackbar({ ...snackbar2, isVisible: v })}
       />
       <SnackbarNoButton
          text={snackbar3.text}
          lang={lang}
          textObj={textObj}
          style={{marginLeft: "5%"}}
          isVisible={snackbar3.isVisible}
          timeout={4000}
          setVisible={v => setSnackbar({ ...snackbar3, isVisible: v })}
       />
       <td width="20%"></td>
       <td width="20%"></td>
    </tr>
    <tr>
      <td colspan="3"><br></br><br></br><br></br><br></br></td>
    </tr>
  </table>
  </div>
  <BottomMenu lang={lang} textObj={textObj}/>
 <LoginTimeout/>
 </>
 )
}
